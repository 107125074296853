import { TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { CountryKey } from "../../../utils/models/Common.model";

export function FormProgress({
  lang,
  translation: translationProp,
  onChange,
}: {
  lang: CountryKey;
  translation?: string;
  onChange: (key: CountryKey, translation: string) => void;
}) {
  const translation: string = translationProp || "";

  return (
    <div className="mt-4">
      <div className="grid grid-rows-2 grid-cols-1 gap-4">
        <div>
          <p className="font-semibold">Translation</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Translation"
              variant="outlined"
              size="small"
              type="text"
              name="translation"
              value={translation}
              onChange={(event) => { onChange(lang, event.target.value) }}
            />
          </div>
        </div>
      </div>

    </div>
  );
}
