import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableRetailerManagement } from "../../components/TableRetailerManagement";
import { useRetailersList } from "../../services/Dynamo/query";
import { useLoader } from "../../services/providers/LoaderProvider";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Retailer } from "../../utils/models/Retailer.model";
import { AwsContext } from "../../services/providers/AWSProvider";
import { sendEmail } from "../../services/SES/ses";
import { useRetailerMutation } from "../../services/Dynamo/mutation";
import CircularProgress from '@mui/material/CircularProgress';
import { relative } from "path";

export default function RetailerManagement() {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useRetailersList();
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useRetailerMutation();
  const { setLoading } = useLoader("RetailerManagement");
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false)
  const [showNotificationFailed, setShowNotificationFailed] = useState<{
    message: string
  } | undefined>(undefined)
  const [currentSearch, setCurrentSearch] = useState<string>("")
  const [filterdItems, setFilteredItems] = useState<Retailer[]>([])
  const [selectedUids, setSelectedUids] = useState<Retailer['uid'][]>([])
  const credentialProvider = useContext(AwsContext);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false)
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const onDelete = useCallback(() => {
    refetch()
    setShowNotificationSuccess(true)
    setTimeout(() => {
      setShowNotificationSuccess(false)
    }, 2000)
  }, [refetch])

  useEffect(() => {
    const timeout = setTimeout(() => {
      const filteredItems = (items || [])?.filter((el) => {
        return el.store_name?.toLowerCase().includes(currentSearch.toLowerCase()) ||
          el.city?.toLowerCase().includes(currentSearch.toLowerCase()) ||
          el.country?.toLowerCase().includes(currentSearch.toLowerCase()) ||
          el.status?.toLowerCase().includes(currentSearch.toLowerCase()) ||
          el.email?.toLowerCase().includes(currentSearch.toLowerCase())

      })
      setFilteredItems(filteredItems)
    }, 1000);
    return () => clearTimeout(timeout)
  }, [currentSearch, items])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActiveAndSendEmail = async () => {
    setAnchorEl(null);
    const failedRetailer: string[] = []
    if (credentialProvider) {
      if (selectedUids?.length === 0) {
        setShowNotificationFailed({
          message: "No retailer selected"
        })
      }
      setIsSendingEmail(true)
      document.body.classList.add('hide-overlay-to-body');
      await Promise.all(selectedUids.map(async (one) => {
        try {
          const retailer = items?.find((retailer) => retailer.uid === one)
          if (retailer) {
            /* AGGIORNO LO STATO */
            const oldRetailer = retailer || {};
            const newRetailer = {
              ...oldRetailer,
              status: "ACTIVE"
            } as Retailer
            await mutateItem(newRetailer)
            /* MANDO LA MAIL */
            const successed = await sendEmail('retailer-approved', retailer.country, retailer?.email, { CODE: retailer?.code as string }, credentialProvider)
            if (successed === false) {
              failedRetailer.push(`Store Brand ${retailer.store_brand}, Store Name ${retailer.store_name}, vat ${retailer.vat} from ${retailer.country}`)
            }
          }
        } catch (error: any) {
          setShowNotificationFailed({
            message: error?.message || 'Error during sending email'
          })
          console.log(error)
        }
      }));
    } else {
      alert('No credentials')
    }
    if (failedRetailer.length > 0) {
      setShowNotificationFailed({
        message: `Error during send email to retailers: ${failedRetailer.join('/')}`
      })
    }
    setIsSendingEmail(false)
    document.body.classList.remove('hide-overlay-to-body');
    setSelectedUids([])
    refetch()
  }
  const handleActive = async () => {
    setAnchorEl(null);
    if (selectedUids?.length === 0) {
      setShowNotificationFailed({
        message: "No retailer selected"
      })
    }
    setIsSendingEmail(true)
    document.body.classList.add('hide-overlay-to-body');
    await Promise.all(selectedUids.map(async (one) => {
      const retailer = items?.find((retailer) => retailer.uid === one)
      const oldRetailer = retailer || {};
      const newRetailer = {
        ...oldRetailer,
        status: "ACTIVE"
      } as Retailer
      await mutateItem(newRetailer)
    }));
    setIsSendingEmail(false)
    document.body.classList.remove('hide-overlay-to-body');
    setSelectedUids([])
    refetch()
  }
  const handlSendEmail = async () => {
    setAnchorEl(null);
    const failedRetailer: string[] = []
    if (credentialProvider) {
      if (selectedUids?.length === 0) {
        setShowNotificationFailed({
          message: "No retailer selected"
        })
      }
      setIsSendingEmail(true)
      document.body.classList.add('hide-overlay-to-body');
      await Promise.all(selectedUids.map(async (one) => {
        try {
          const retailer = items?.find((retailer) => retailer.uid === one)
          if (retailer) {
            const successed = await sendEmail('retailer-approved', retailer.country, retailer?.email, { CODE: retailer?.code as string }, credentialProvider)
            if (successed === false) {
              failedRetailer.push(`Store Brand ${retailer.store_brand}, Store Name ${retailer.store_name}, vat ${retailer.vat} from ${retailer.country}`)
            }
          }
        } catch (error: any) {
          setShowNotificationFailed({
            message: error?.message || 'Error during sending email'
          })
          console.log(error)
        }
      }));
    } else {
      alert('No credentials')
    }
    if (failedRetailer.length> 0) {
      setShowNotificationFailed({
        message: `Error during send email to retailers: ${failedRetailer.join('/')}`
      })
    }
    setIsSendingEmail(false)
    document.body.classList.remove('hide-overlay-to-body');
    setSelectedUids([])
  }
  const activatedRetailer = items?.filter((el: Retailer) => { return el.status === 'ACTIVE' }) || []
  let canActive = false
  for (let i = 0; i < activatedRetailer?.length; i++) {
    if ((selectedUids || []).includes(activatedRetailer[i].uid)) {
      canActive = true
      break
    }
  }

  return (
    <div className="container mx-auto" style={{ overflow: "hidden" }}>
      <div className="grid grid-cols-2 grid-rows-1 mt-6" >
        <h1 className="text-3xl">Retailer Management List</h1>
        <div className="flex justify-end">
          <Button
            variant="contained"
            onClick={() => navigate("/retailer-management/new")}
            className="buttons-style"
          >
            <AddCircleIcon className="icon-button" />
            <span className="w-full">add retailer</span>
          </Button>
          <div>
            <Button
              sx={{ border: "!1px solid red !important" }}
              className="!ml-3 buttons-style-variant"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MoreVertIcon className="icon-button" />
              <span className="w-full">Option Group</span>
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem disabled={canActive} onClick={handleActiveAndSendEmail}>Activate retailers and send mail</MenuItem>
              <MenuItem disabled={canActive} onClick={handleActive}>Activate retailers</MenuItem>
              <MenuItem onClick={handlSendEmail}>Send mail to retailers</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div className="my-3">
        <p className="font-semibold">Insert search terms</p>
        <div className="mt-1">
          <TextField
            fullWidth
            label="Insert search terms"
            variant="outlined"
            size="small"
            type="text"
            name="title"
            value={currentSearch}
            onChange={(e) => { setCurrentSearch(e.target.value) }}
          />
        </div>
      </div>
      <TableRetailerManagement
        isLoading={isLoading}
        retailers={filterdItems || []}
        onDelete={onDelete}
        selectedUids={selectedUids}
        setSelectedUids={setSelectedUids} />
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>
        </Snackbar>
      }
      {showNotificationFailed &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={10000}
          onClose={() => { setShowNotificationFailed(undefined) }}
          message={showNotificationFailed.message}
        >
          <Alert className="!text-white" sx={{ color: "white", backgroundColor: "red" }} severity="error">
            {showNotificationFailed.message}
          </Alert>
        </Snackbar>
      }
      {isSendingEmail &&
        <div className="blocker-overlay">
          <div style={{ position: "absolute", top: "50%", left: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <CircularProgress />
            <div className="text-white">Operation in progress</div>
          </div>
        </div>
      }
    </div>
  );
}
