import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { AuthProvider, AuthProviderProps, useAuth } from "oidc-react";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { Layout } from "./components/Layout";
import { AuthLoaderPage } from "./pages/AuthLoaderPage";
import { AWSProvider } from "./services/providers/AWSProvider";
import { LoaderProvider } from "./services/providers/LoaderProvider";

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
  responseType: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  redirectUri: window.location.origin + process.env.REACT_APP_AUTH_REDIRECT_PATH,
  autoSignIn: false, //controllare se effettivamente
  scope: process.env.REACT_APP_AUTH_SCOPE,
};

const theme = createTheme({
  typography: {
    fontFamily: "Nunito, sans-serif",
  },
});

function App() {
  return (
    <React.StrictMode>
      <LoaderProvider>
        <AuthProvider {...oidcConfig}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<Inner />}></Route>
                <Route path="/callback" element={<Callback />}></Route>
              </Routes>
            </BrowserRouter>
          </ThemeProvider>
        </AuthProvider>
      </LoaderProvider>
    </React.StrictMode>
  );
}

function Callback() {
  const { isLoading, userData, signIn } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && userData) {
      navigate("/devices");
    }
    if (!isLoading && !userData) {
      const timeout = setTimeout(() => {
        signIn();
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [isLoading, navigate, signIn, userData]);
  return <AuthLoaderPage />;
}
function Inner() {
  const { isLoading, userData, signIn } = useAuth();
  const idToken = userData?.id_token;
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (!idToken || userData?.expired) {
      signIn();
    }
  }, [idToken, isLoading, signIn, userData?.expired]);

  if (isLoading || !idToken) {
    return <AuthLoaderPage />;
  }

  return (
    <AWSProvider idToken={idToken}>
      <Layout />
    </AWSProvider>
  );
}

export default App;
