import { Box, Tab, Tabs } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FormFaq } from "./FormFaq";
import { Faq, FaqTranslation } from "../../../utils/models/Faq.model";
import { Country, CountryKey } from "../../../utils/models/Common.model";
import { formatISO } from "date-fns";

export function TabsLangFaq({
  langs,
  translations,
  setTranslations,
}: {
  langs: CountryKey[];
  translations: Faq["translations"];
  setTranslations: Dispatch<SetStateAction<Faq["translations"]>>;
}) {
  const [value, setValue] = useState<undefined | CountryKey>(undefined);
  useEffect(() => {
    if (value === undefined) {
      setValue(langs[0]);
    }
  }, [langs, value]);
  const setTranslation = useCallback(
    (key: CountryKey, translation: FaqTranslation) => {
      setTranslations((value) => ({
        ...value,

        [key]: {
          ...translation,
          created_at: translation.created_at || formatISO(new Date())
        },
      }));
    },
    [setTranslations]
  );

  return (
    <>
      {langs.length === 0 && <p className="mt-4">No device selected</p>}
      {langs.length > 0 && (
        <div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value || false}
                onChange={(_, value) => setValue(value)}
                aria-label="basic tabs example"
              >
                {langs.map((key) => (
                  <Tab key={key} label={Country[key]} value={key} />
                ))}
              </Tabs>
            </Box>
            {langs.map((key) => (
              <div role="tabpanel" key={key} hidden={value !== key}>
                <FormFaq
                  translation={translations[key]}
                  lang={key}
                  onChange={setTranslation}
                />
              </div>
            ))}
          </Box>
        </div>
      )}
    </>
  );
}
