import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Collapse, Divider, IconButton, List, ListItem, ListItemText, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { ChangeEvent, Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TranslationEntity } from "../utils/models/Common.model";
import DeletionConfirm from "./DeletionConfirm";
import { useTranslationDeletion } from "../services/Dynamo/delete";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function TableTranslations({ translations, isLoading, onDelete }: { translations: TranslationEntity[], isLoading: boolean, onDelete: (row: TranslationEntity) => void }) {
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table
          
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">#</StyledTableCell>
              <StyledTableCell>Language</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Translations</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <StyledTableCell align="center" colSpan={5}>
                  Fetching data...
                </StyledTableCell>
              </StyledTableRow>
            )}
            {translations.length === 0 && !isLoading && (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <StyledTableCell align="center" colSpan={5}>
                  No Translations Availables.
                </StyledTableCell>
              </StyledTableRow>
            )}
            {translations.map((row, index) => (
              <TranslationRow key={row.uid} row={row} onDelete={onDelete} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200, 500]}
        component="div"
        count={translations.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
function TranslationRow(props: { row: TranslationEntity, onDelete: (row: TranslationEntity) => void }) {
  const navigate = useNavigate()
  const { row, onDelete } = props;
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)
  const handleClose = useCallback(() => {
    setDeleteModal(false)
  }, [])
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem
  } = useTranslationDeletion();
  const deleteTranslation = useCallback(() => {
    deleteItem(row.uid).then((res) => {
      onDelete(row)
    });
    setDeleteModal(false)
  }, [deleteItem, onDelete, row])
  return (
    <>
      {deleteModal && (
        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this translation '${row.label}'?`}
          deletionMethod={deleteTranslation} />
      )}
      <Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="center">{row.uid}</StyledTableCell>
          <StyledTableCell align="center">{row.defaultMessage}</StyledTableCell>
          <StyledTableCell align="center">{row.description}</StyledTableCell>
          <StyledTableCell align="center" sx={{ minWidth: "176px" }}>
            <Button
              variant="text"
              onClick={() => navigate(`/translations/${row.uid}`)}
              className="buttons-edit-delete-style mr-4"
            >
              <EditIcon />
            </Button>
            <Button
              variant="text"
              onClick={() => setDeleteModal(true)}
              className="buttons-edit-delete-style"
            >
              <DeleteIcon />
            </Button>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <List className="p-0">
                  {(!row?.translations || Object.keys(row?.translations).length === 0) && (
                    <Fragment
                      key={row.uid}
                    >
                      <ListItem>
                        <ListItemText sx={{ textAlign: "center" }} primary={"No translataions saved."} />
                      </ListItem>
                    </Fragment>
                  )}
                  {row?.translations && Object.keys(row.translations).map((language, i) => (
                    <Fragment
                      key={row.translations[i]?.translation}
                    >
                      {i > 0 && <Divider />}
                      <ListItem>
                        <ListItemText sx={{ textAlign: "left" }} primary={language} />
                        <ListItemText
                          sx={{ textAlign: "center", maxWidth: "800px" }}
                          primary={row.translations[language]?.translation || ''}
                        />
                        <ListItemText sx={{ textAlign: "right" }} primary={row.translations[language]?.description || ''} />
                      </ListItem>
                    </Fragment>
                  ))}
                </List>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </Fragment>
    </>
  )
}
