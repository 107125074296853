import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Button, CircularProgress, Snackbar, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslationGet } from "../../services/Dynamo/get";
import { useTranslationMutation } from "../../services/Dynamo/mutation";
import { CountryKey, TranslationEntity, countries } from "../../utils/models/Common.model";
import { TabsTranslation } from "./pieces/TabsTranslation";

export default function Translation() {
  const langs: CountryKey[] = countries as CountryKey[]
  const navigate = useNavigate();
  const { uidTranslation } = useParams();
  const isModify = uidTranslation !== "new";
  const {
    isLoading,
    isStale,
    item: translation,
    refetch,
  } = useTranslationGet(uidTranslation as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useTranslationMutation();


  // campi
  const [label, setLabel] = useState("");
  const [defaultMessage, setDefaultMessage] = useState("");
  const [description, setDescription] = useState('');
  const [translations, setTranslations] = useState<TranslationEntity["translations"]>({});

  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false)
  const isFormValid = label !== "" && defaultMessage !== ""

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
      };
  useEffect(() => {
    setLabel(translation?.label || "");
    setDefaultMessage(translation?.defaultMessage || '');
    setDescription(translation?.description || '');
    setTranslations(translation?.translations || {})
  }, [translation]);

  const handleSave = () => {
    const oldTranslation = translation || {};
    mutateItem({
      ...oldTranslation,
      label,
      defaultMessage,
      description,
      translations
    } as TranslationEntity).then((res) => {
      setShowNotificationSuccess(true)
      setTimeout(() => {
        setShowNotificationSuccess(false)
        if (isModify) {
          refetch();
        } else {
          navigate(`/translations/${res.uid}`);
        }
      }, 2000)
      if (isModify) {
        refetch();
      } else {
        navigate(`/translations/${res.uid}`);
      }
    });
  };
  useEffect(() => {
    if (!translation && !isStale && !isLoading && isModify) {
      navigate("../");
    }
  }, [isLoading, isModify, isStale, navigate, translation])
  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">
              {isModify ? `Edit Translation: ${translation?.label}` : "New Translation"}
            </h1>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={!isFormValid || isLoadinMut}
              className="buttons-style"
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>

          </div>
          <div className="grid grid-rows-1 grid-cols-6 gap-2">
            <div className="col-span-4">
              <p className="font-semibold">Label *</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Label"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  value={label}
                  onChange={handleChangeString(setLabel)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Default Message *</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Default Message"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="order"
                  value={defaultMessage}
                  onChange={handleChangeString(setDefaultMessage)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Description</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Description"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="order"
                  value={description}
                  onChange={handleChangeString(setDescription)}
                />
              </div>
            </div>

          </div>
          <TabsTranslation
            langs={langs}
            translations={translations}
            setTranslations={setTranslations}
          />
        </>
      )}
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>

        </Snackbar>
      }
    </div>
  );
}
