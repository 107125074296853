import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStoreBrandGet } from "../../services/Dynamo/get";
import {
  useStoreBrandMutation
} from "../../services/Dynamo/mutation";
import { CountryKey } from "../../utils/models/Common.model";
import { StoreBrand } from "../../utils/models/StoreBrand.model";

export default function NewStoreBrand() {
  const navigate = useNavigate();
  const { uidStoreBrand } = useParams();
  const isModify = uidStoreBrand !== "new";
  const {
    isLoading,
    item: storeBrand,
    isStale,
    refetch,
  } = useStoreBrandGet(uidStoreBrand as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useStoreBrandMutation();
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false)

  // campi
  const [name, setName] = useState("");
  const [country, setCountries] = useState<CountryKey[]>([]);

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
      };
  const handleChangeCountry = (event: SelectChangeEvent) => {
    const selected = event.target.value as string;
    if (selected === "be-nl") {
      setCountries(["be", "nl"]);
    } else {
      setCountries([selected as CountryKey]);
    }
  };

  useEffect(() => {
    setName(storeBrand?.name || "");
    setCountries(storeBrand?.countries || []);
  }, [storeBrand]);

  const handleSave = () => {
    const oldStoreBrand = storeBrand || {};
    mutateItem({
      ...oldStoreBrand,
      name,
      countries: country,
    } as StoreBrand).then((res) => {
      setShowNotificationSuccess(true)
      setTimeout(() => {
        setShowNotificationSuccess(false)
        if (isModify) {
          refetch();
        } else {
          navigate(`/store-brand/${res.uid}`);
        }
      }, 2000)
    });
  };

  if (!storeBrand && !isStale && !isLoading && isModify) {
    navigate("../");
  }
  const isFormValid = name !== "" && country !== undefined

  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">
              {isModify
                ? `Edit Store Brand: ${storeBrand?.name}`
                : "New Store Brand"}
            </h1>
            <Button
              variant="contained"
              onClick={handleSave}
              className="buttons-style"
              disabled={!isFormValid}
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
          <div className="grid grid-rows-1 grid-cols-4 gap-2">
            <div className="col-span-3">
              <p className="font-semibold">Name Store Brand</p>
              <div className="mt-1">
                <TextField
                  error={name === ""}
                  fullWidth
                  label="Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChangeString(setName)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Country</p>
              <div className="mt-1">
                <FormControl fullWidth size="small">
                  <InputLabel >
                    Country
                  </InputLabel>
                  <Select
                    error={country[0] === undefined}
                    required
                    value={country.length === 2 ? 'be-nl' : country[0] || ''}
                    label="Country"
                    onChange={handleChangeCountry}
                  >
                    <MenuItem disabled value="">Select</MenuItem>
                    <MenuItem value={"it"}>it</MenuItem>
                    <MenuItem value="fr">fr</MenuItem>
                    <MenuItem value="es">es</MenuItem>
                    <MenuItem value="pt">pt</MenuItem>
                    <MenuItem value={"de"}>de</MenuItem>
                    <MenuItem value="pl">pl</MenuItem>
                    <MenuItem value="be-nl">be-nl</MenuItem>
                    <MenuItem value="tr">tr</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </>
      )}
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>

        </Snackbar>
      }
    </div>
  );
}
