import { Category } from '../../utils/models/Category.model';
import { TranslationEntity } from '../../utils/models/Common.model';
import { CustomerContactUs, RetailerContactUs } from '../../utils/models/ContactUs.model';
import { Device } from '../../utils/models/Device.model';
import { Faq } from '../../utils/models/Faq.model';
import { MailTemplate } from '../../utils/models/MailTemplate.model';
import { Manual } from '../../utils/models/Manual.model';
import { Progress } from '../../utils/models/Progress.model';
import { Retailer } from '../../utils/models/Retailer.model';
import { IssueAndSpecification } from '../../utils/models/Spec.model';
import { StoreBrand } from '../../utils/models/StoreBrand.model';
import { CustomerTicket, RetailerTicket } from '../../utils/models/Ticket.model';
import { useDynamoQuery } from '../providers/AWSProvider';
import { TableName, useEntityList } from './common';


/* QUERY */
export function useDeviceList() {return useEntityList<Device>('device')}
export function useFaqList() {return useEntityList<Faq>('faq')}
export function useManualList() {return useEntityList<Manual>('download')}
export function useCategoryList() {return useEntityList<Category>('category')}
export function useCustomerContactUsList() {return useEntityList<CustomerContactUs>('contactUs#customer')}
export function useRetailerContactUsList() {return useEntityList<RetailerContactUs>('contactUs#retailer')}
export function useCustomerTicketFormList() {return useEntityList<CustomerTicket>('ticketform#customer')}
export function useRetailerTicketFormList() {return useEntityList<RetailerTicket>('ticketform#retailer')}
export function useStoreBrandList() {return useEntityList<StoreBrand>('storebrand')}
export function useMailTemplatesList() { return useEntityList<MailTemplate>('mail_template') }
export function useSpecList() { return useEntityList<IssueAndSpecification>('issue') }
export function useRetailersList() { return useEntityList<Retailer>('retailer') }
export function useTranslationsList() { return useEntityList<TranslationEntity>('translation') }
export function useProgressList() { return useEntityList<Progress>('progress') }
export function useDeviceFaqList(deviceId: number) {
    return useDynamoQuery({
        TableName,
        KeyConditionExpression: 'pk = :pk and begins_with(sk,:sk)',
        ExpressionAttributeValues: {
            ':pk': { S: `device#${deviceId}` },
            ':sk': { S: `faq#` },
            
        },
    })
}
export function useDeviceDownloadList(deviceId: number) {
    return useDynamoQuery({
        TableName,
        KeyConditionExpression: 'pk = :pk and begins_with(sk,:sk)',
        ExpressionAttributeValues: {
            ':pk': { S: `device#${deviceId}` },
            ':sk': { S: `download#` },
        },
    })
}