import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDeviceGet } from "../../services/Dynamo/get";
import { useDeviceMutation } from "../../services/Dynamo/mutation";
import { Device, DeviceAsset } from "../../utils/models/Device.model";
import { CountryKey } from "../../utils/models/Common.model";
import { uploadFileToS3 } from "../../services/S3/S3";
import { AwsContext } from "../../services/providers/AWSProvider";
import { useLoader } from "../../services/providers/LoaderProvider";
import { useDeviceList } from "../../services/Dynamo/query";
var slugify = require('slugify')
export default function NewDevice() {
  const credentialProvider = useContext(AwsContext);
  const navigate = useNavigate();
  const { uidDevice } = useParams();
  const isModify = uidDevice !== "new";
  const {
    isLoading: isLoadingList,
    items,
  } = useDeviceList();
  const {
    isLoading: isLoadingGet,
    item: device,
    refetch,
    isStale
  } = useDeviceGet(uidDevice as string, isModify);
  const isLoading = isLoadingList && isLoadingGet;
  const { setLoading } = useLoader("Device");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useDeviceMutation();

  // campi
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [macroModel, setMacroModel] = useState("");
  const [order, setOrder] = useState(0);
  const [code, setCode] = useState(0);
  const [countries, setCountries] = useState<string[]>([]);
  const [profileModel, setProfileModel] = useState("");
  const [ean, setEan] = useState("");
  const [assets, setAssets] = useState<
    Array<DeviceAsset & { isLoading?: boolean }>
  >([{ type: "device", path: "" }, { type: "pack", path: "" }, { type: "thumb", path: "" }]);
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false)
  
  // images
  const handleImageChange =
    (type: "device" | "pack" | "thumb") =>
      (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
          const file = e.target.files[0];
          setAssets(
            assets.map((asset) => {
              if (asset.type !== type) {
                return asset;
              }
              return {
                ...asset,
                isLoading: true,
              };
            })
          );
          uploadFileToS3(file, file.name, credentialProvider!, `device/${device?.code}`)
            .then((res) => {
              setAssets((assets) =>
                assets.map((asset) => {
                  if (asset.type !== type) {
                    return asset;
                  }
                  return {
                    ...asset,
                    path: res,
                    isLoading: false,
                  };
                })
              );
            })
            .catch(() => {
              alert("Errore Caricamento File");
            });
        }
      };

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
      };
  const handleChangeNumber =
    (setter: Dispatch<SetStateAction<number>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.valueAsNumber);
      };
  const handleChangeSelect =
    (setter: Dispatch<SetStateAction<string>>) =>
      (event: SelectChangeEvent) => {
        setter(event.target.value as string);
      };
  const handleChangeCountry = (event: SelectChangeEvent) => {
    const selected = event.target.value as string;
    if (selected === "be-nl") {
      setCountries(["be", "nl"]);
    } else {
      setCountries([selected as CountryKey]);
    }
  };

  useEffect(() => {
    setTitle(device?.title || "");
    setSlug(device?.slug || slugify((device?.title || ""), {
      replacement: '-',
      remove: undefined,
      lower: false,
      strict: true,
      locale: 'vi',
      trim: true
    }) as string);
    setMacroModel(device?.macroModel || "");
    setOrder(device?.order || 0);
    setCode(device?.code || 0);
    setCountries(device?.countries || []);
    setProfileModel(device?.profileModel || "");
    setEan(device?.ean || "");
    setAssets((device?.assets && device?.assets.length > 0) ? device?.assets : [{ type: "device", path: "" }, { type: "pack", path: "" }, { type: "thumb", path: "" }]);
  }, [device]);

  const handleSave = () => {
    const oldDevice = device || {};
    mutateItem({
      ...oldDevice,
      title,
      macroModel,
      order,
      slug,
      code,
      countries,
      profileModel,
      ean,
      tk: 'code#'+code,
      assets: assets.map((asset) => ({
        type: asset.type,
        path: asset.path,
      })),
    } as unknown as Device).then((res) => {
      setShowNotificationSuccess(true)
      setTimeout(() => {
        setShowNotificationSuccess(false)
        if (isModify) {
          refetch();
        } else {
          navigate(`/devices/${res.uid}`);
        }
      }, 2000)
    });
  };

  if (!device && !items && !isStale && isLoading === false && isModify) {
    navigate("../");
  }
  useEffect(() => {
    const newslug = slugify(title, {
      replacement: '-',
      remove: undefined,
      lower: false,
      strict: true,
      locale: 'vi',
      trim: true
    }) as string
    setSlug(newslug)
  }, [title])

  const codeAlreadyAssygned = items?.some((device) => device.code === code) && !isModify;
  const isFormValid = title !== "" && macroModel !== "" && order > 0 && code > 0 && countries[0] !== undefined && profileModel !== "" && !codeAlreadyAssygned;
  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">
              {isModify ? `Edit Device: ${device?.title}` : "New Device"}
            </h1>
            <Button
              disabled={!isFormValid}
              variant="contained"
              onClick={handleSave}
              className="buttons-style"
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
          <div>
            <div className="">
              <p className="font-semibold">Title *</p>
              <div className="mt-1">
                <TextField
                  error={title === ""}
                  fullWidth
                  required
                  label="Title"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="title"
                  value={title}
                  onChange={handleChangeString(setTitle)}
                />
              </div>
            </div>
            <div className="">
              <p className="font-semibold">Slug *</p>
              <div className="mt-1">
                <TextField
                  disabled={true}
                  error={slug === ""}
                  fullWidth
                  required
                  label="Slug"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="slug"
                  value={slug}

                />
              </div>
            </div>
            <div className="grid grid-rows-1 grid-cols-3 gap-2 mt-4">
              <div>
                <p className="font-semibold">Type *</p>
                <div className="mt-1">
                  <FormControl fullWidth size="small" disabled={isModify}>
                    <InputLabel>Type *</InputLabel>
                    <Select
                      error={macroModel === ""}
                      required
                      value={macroModel}
                      label="Type"
                      onChange={handleChangeSelect(setMacroModel)}
                    >
                      <MenuItem value="Clempad">Clempad</MenuItem>
                      <MenuItem value="Clemstation">Clemstation</MenuItem>
                      <MenuItem value="Clem Phone / Clempad Call">
                        Clem Phone / Clempad Call
                      </MenuItem>
                      <MenuItem value="My Clem Box">My Clem Box</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <p className="font-semibold">Order *</p>
                <div className="mt-1">
                  <TextField
                    error={order === 0}
                    fullWidth
                    required
                    label="Order"
                    variant="outlined"
                    size="small"
                    type="number"
                    name="order"
                    value={order}
                    onChange={handleChangeNumber(setOrder)}
                  />
                </div>
              </div>
              <div>
                <p className="font-semibold">Device Code *{codeAlreadyAssygned ? ' (Device code altready assigned)' : ''}</p>
                <div className="mt-1">
                  <TextField
                    error={code === 0 || codeAlreadyAssygned}
                    fullWidth
                    required
                    disabled={isModify}
                    label="Device Code"
                    variant="outlined"
                    size="small"
                    type="number"
                    name="code"
                    value={code}
                    onChange={handleChangeNumber(setCode)}
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-rows-1 grid-cols-3 gap-2 mt-4">
              <div>
                <p className="font-semibold">Country *</p>
                <div className="mt-1">
                  <FormControl fullWidth size="small" disabled={isModify}>
                    <InputLabel>Country *</InputLabel>
                    <Select
                      error={countries[0] === undefined}
                      required
                      value={countries.length === 2 ? 'be-nl' : countries[0] || ''}
                      label="Country *"
                      onChange={handleChangeCountry}
                    >
                      <MenuItem disabled value="">Select</MenuItem>
                      <MenuItem value='it'>it</MenuItem>
                      <MenuItem value="fr">fr</MenuItem>
                      <MenuItem value="es">es</MenuItem>
                      <MenuItem value="pt">pt</MenuItem>
                      <MenuItem value="de">de</MenuItem>
                      <MenuItem value="pl">pl</MenuItem>
                      <MenuItem value="be-nl">be-nl</MenuItem>
                      <MenuItem value="tr">tr</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div>
                <p className="font-semibold">Device Name *</p>
                <div className="mt-1">
                  <TextField
                    error={profileModel === ""}
                    fullWidth
                    required
                    label="Device Name"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="profileModel"
                    value={profileModel}
                    onChange={handleChangeString(setProfileModel)}
                  />
                </div>
              </div>
              <div>
                <p className="font-semibold">Ean Code</p>
                <div className="mt-1">
                  <TextField
                    fullWidth
                    label="Ean Code"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="ean"
                    value={ean}
                    onChange={handleChangeString(setEan)}
                  />
                </div>
              </div>
            </div>
            {isModify && (


              <div className="mt-8">
                <h2 className="text-3xl">Thumbnails</h2>
                <div className="grid grid-rows-1 grid-cols-3 gap-2">
                  <div>
                    <p className="font-semibold">Small image *</p>
                    <p className="text-sm">(Only PNG) size: 55x45 pixels</p>
                    <div className="mt-1">
                      <div>
                        <Button
                          className="buttons-style"
                          variant="contained"
                          component="label"
                        >
                          Upload Small Image
                          <input
                            type="file"
                            accept="image/png"
                            style={{ display: "none" }}
                            onChange={handleImageChange("thumb")}
                          />
                        </Button>
                        <div className="mt-1">
                          <DeviceImage
                            assets={assets}
                            type="thumb"
                          ></DeviceImage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-semibold">Package image *</p>
                    <p className="text-sm">(Only PNG) size: 200x158 pixels</p>
                    <div className="mt-1">
                      <div>
                        <Button
                          className="buttons-style"
                          variant="contained"
                          component="label"
                        >
                          Upload Package image
                          <input
                            type="file"
                            accept="image/png"
                            style={{ display: "none" }}
                            onChange={handleImageChange("pack")}
                          />
                        </Button>
                        <div className="mt-1">
                          <DeviceImage
                            assets={assets}
                            type="pack"
                          ></DeviceImage>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="font-semibold">Product image *</p>
                    <p className="text-sm">(Only PNG) size: 200x152 pixels</p>
                    <div className="mt-1">
                      <div>
                        <Button
                          className="buttons-style"
                          variant="contained"
                          component="label"
                        >
                          Upload Product image
                          <input
                            type="file"
                            accept="image/png"
                            style={{ display: "none" }}
                            onChange={handleImageChange("device")}
                          />
                        </Button>
                        <div className="mt-1">
                          <DeviceImage
                            assets={assets}
                            type="device"
                          ></DeviceImage>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>

        </Snackbar>
      }
    </div>
  );
}

function DeviceImage({
  assets,
  type,
}: {
  assets: Array<DeviceAsset & { isLoading?: boolean }>;
  type: "device" | "pack" | "thumb";
}) {
  const [image, setImage] = useState<{
    isLoading?: boolean,
    path: string,
    type: "device" | "pack" | "thumb"
  }>()
  // const image = assets?.find((asset) => asset.type === type);
  useEffect(() => {
    setImage(assets?.find((asset) => asset.type === type))
  }, [assets, type])
  if (image && image.isLoading) {
    return <CircularProgress />;
  } else if (image?.path === '') {
    return <p className="text-red-600">missing image</p>;
  } else if (image && !image.isLoading) {
    return <img src={image.path} alt={type} />;
  } else {
    return <></>;
  }

}
