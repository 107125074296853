import { TextField } from "@mui/material";
import { formatISO } from "date-fns";
import { ChangeEvent } from "react";
import { TextEditor } from "../../../components/TextEditor";
import { Country, CountryKey } from "../../../utils/models/Common.model";
import { FaqTranslation } from "../../../utils/models/Faq.model";

export function FormFaq({
  lang,
  translation: translationProp,
  onChange,
}: {
  lang: CountryKey;
  translation?: FaqTranslation;
  onChange: (key: CountryKey, translation: FaqTranslation) => void;
}) {
  const translation: FaqTranslation = translationProp || {
    title: "",
    subtitle: "",
    body: "",
    country: Country[lang],
    created_at: formatISO(new Date()),
  };
  const handleChangeField =
    (field: keyof FaqTranslation) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(lang, {
        ...translation,
        [field]: event.target.value,
      });
    };

  const handleChangeBody = (content: string) => {
    onChange(lang, {
      ...translation,
      body: content,
    });
  };

  return (
    <div className="mt-4">
      <div className="grid grid-rows-2 grid-cols-1 gap-4">
        <div>
          <p className="font-semibold">Title</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              size="small"
              type="text"
              name="title"
              value={translation.title}
              onChange={handleChangeField("title")}
            />
          </div>
        </div>
        <div>
          <p className="font-semibold">Subtitle</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Subtitle"
              variant="outlined"
              size="small"
              type="text"
              name="subtitle"
              value={translation.subtitle}
              onChange={handleChangeField("subtitle")}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold mb-1">Editor</p>
        <TextEditor body={translation.body} prefix="faq_body_attachment" setBody={handleChangeBody} />
      </div>
    </div>
  );
}
