import { TabsLangTicketFormCustomer } from "./pieces/TabsLangTicketFormCustomer";
import { TabsLangTicketFormRetailer } from "./pieces/TabsLangTicketFormRetailer";

export default function CustomizeTicketForm() {
  return (
    <div className="container mx-auto">
      <div className="">
        <h1 className="text-3xl">Ticket Form Customer</h1>
        <p className="font-bold">Fields</p>
        <p className="text-sm">
          Customize which fields to display in the ticket form Customer
        </p>
        <TabsLangTicketFormCustomer />
      </div>
      <hr className="my-12 border-black" />
      <div className="">
        <h1 className="text-3xl">Ticket Form Retailer</h1>
        <p className="font-bold">Fields</p>
        <p className="text-sm">
          Customize which fields to display in the ticket form Retailer
        </p>
        <TabsLangTicketFormRetailer />
      </div>
    </div>
  );
}
