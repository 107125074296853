import { useContext } from "react";
import { LoaderContext } from "../services/providers/LoaderProvider";
import { LinearProgress } from "@mui/material";

export function GlobalLoader() {
  const { isLoading, loaders } = useContext(LoaderContext);
  if (isLoading) {
    const title = Object.entries(loaders).filter(([, val])=> val).map(([key]) => key).join("\n");
    return <LinearProgress sx={{ height: '6px' }} title={title} />;
  }
  return <></>;
}
