import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Button, CircularProgress, Snackbar, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProgressGet } from "../../services/Dynamo/get";
import { useProgressMutation } from "../../services/Dynamo/mutation";
import { CountryKey, countries } from "../../utils/models/Common.model";
import { Progress } from "../../utils/models/Progress.model";
import { TabsProgress } from "./pieces/TabsProgress";
function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(rgb: string) {
  rgb = rgb.replace('rgba(', '')
  rgb = rgb.replace(')', '')
  const rgbvalue = rgb.split(',')
  return "#" + componentToHex(parseInt(rgbvalue[0])) + componentToHex(parseInt(rgbvalue[1])) + componentToHex(parseInt(rgbvalue[2]));
}
function hexToRgbA(hex: string) {
  let red = parseInt(hex.substring(1, 3), 16);
  let green = parseInt(hex.substring(3, 5), 16);
  let blue = parseInt(hex.substring(5, 7), 16);

  // Concatenate these codes into proper RGBA format
  return ` rgba(${red}, ${green}, ${blue}, ${1})`
}

const getHex = function (input: string) {
  const match = input.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  return (match && match.length === 4) ? "#" +
    ("0" + parseInt(match[1], 10).toString(16)).slice(-2) +
    ("0" + parseInt(match[2], 10).toString(16)).slice(-2) +
    ("0" + parseInt(match[3], 10).toString(16)).slice(-2) : '';
};

// const invertColor = function() {
//   let rgba = [].slice.call(arguments).join(",").replace(/rgb\(|\)|rgba\(|\)|\s/gi, '').split(',');

//   for (var i = 0; i < rgba.length; i++) {
//     rgba[i] = (i === 3 ? 1 : 255) - rgba[i];
//   }

//   return 'rgba(' + rgba.join(", ") + ')';
// };
export default function SingleProgress() {
  const langs: CountryKey[] = countries as CountryKey[]
  const navigate = useNavigate();
  const { uidProgress } = useParams();
  const isModify = uidProgress !== "new";
  const {
    isLoading,
    isStale,
    item: progress,
    refetch,
  } = useProgressGet(uidProgress as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useProgressMutation();


  const [name, setName] = useState<Progress['name']>("");
  const [zen_desk_status, setZenDeskStatus] = useState<Progress['zen_desk_status']>("");
  const [value, setValue] = useState<Progress['value']>(0);
  const [progressBar, setProgressBar] = useState<Progress['progress_bar']>({
    color: 'rgba(255, 255, 255, 1)',
    fallbackColor: '#FFFFFF',
    invertedColor: '#000000'
  });
  const [translations, setTranslations] = useState<Progress["translations"] | undefined>(undefined);
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false)
  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
      };
  const handleChangeNumber =
    (setter: Dispatch<SetStateAction<number>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(parseInt(event.target.value));
      };
  useEffect(() => {
    setName(progress?.name || "");
    setZenDeskStatus(progress?.zen_desk_status || "");
    setValue(progress?.value || 0);
    setProgressBar(progress?.progress_bar || {
      color: 'rgba(255, 255, 255, 1)',
      fallbackColor: '#FFFFFF',
      invertedColor: '#000000'
    });
    setTranslations(progress?.translations)
  }, [progress]);
  const isFormValid = name !== "" && zen_desk_status !== "" && (value !== null && value !== undefined) && progressBar?.color !== ""

  const handleSave = () => {
    const oldProgress = progress || {};
    const newProgress = {
      ...oldProgress,
      name,
      zen_desk_status,
      value,
      progress_bar: progressBar,
      translations
    } as Progress
    mutateItem(newProgress).then((res) => {
      setShowNotificationSuccess(true)
      setTimeout(() => {
        setShowNotificationSuccess(false)
        if (isModify) {
          refetch();
        } else {
          navigate(`/progress/${res.uid}`);
        }
      }, 2000)
    });
  };
  useEffect(() => {
    if (!progress && !isStale && !isLoading && isModify) {
      navigate("../");
    }
  }, [isLoading, isModify, isStale, navigate, progress])
  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">
              {isModify ? `Edit progress: ${progress?.name}` : "New progress"}
            </h1>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={!isFormValid || isLoadinMut}
              className="buttons-style"
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
          <div className="grid grid-rows-1 grid-cols-6 gap-2">
            <div className="col-span-1">
              <p className="font-semibold">Name *</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="name"
                  value={name}

                  onChange={handleChangeString(setName)}
                />
              </div>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">ZenDesk Ticket Status *</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="ZenDesk Ticket Status"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="order"
                  value={zen_desk_status}
                  onChange={handleChangeString(setZenDeskStatus)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Value *</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Value"
                  variant="outlined"
                  size="small"
                  type="number"
                  name="order"
                  value={value}
                  onChange={handleChangeNumber(setValue)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Color *</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Color"
                  variant="outlined"
                  size="small"
                  type="color"
                  name="order"
                  value={rgbToHex(progressBar.color)}
                  onChange={(e) => {
                    setProgressBar({
                      color: hexToRgbA(e.target.value),
                      fallbackColor: "string",
                      invertedColor: "string"
                    })
                  }}
                />
              </div>
            </div>
          </div>
          <div>
            <TabsProgress
              langs={langs}
              translations={translations}
              setTranslations={setTranslations}
            />
          </div>
        </>
      )}
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>

        </Snackbar>
      }
    </div>
  );
}
