import { DeleteItemCommandInput, GetItemCommandInput } from "@aws-sdk/client-dynamodb";
import { marshall, unmarshall } from "@aws-sdk/util-dynamodb";
import { formatISO } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { uid as createUID } from 'uid';
import { giveMeTheType, giveMeTheUID, useDynamoDelete, useDynamoGetItem, useDynamoMutation, useDynamoQuery } from "../providers/AWSProvider";

export const TableName = process.env.REACT_APP_TABLENAME

export function useEntityList<T>(entityType: string) {
    const command = useMemo(() => ({
        TableName,
        KeyConditionExpression: 'sk = :sk',
        ExpressionAttributeValues: {
            ':sk': { S: `entity#${entityType}` },
        },
        IndexName: 'GSI-SK-PK'
    }), [entityType])
    return useDynamoQuery<T>(command)
}
export function useEntityGetItem<T>(entityType: string, uid: string, autoFetch = true) {
    const command: GetItemCommandInput = useMemo(() => ({
        TableName,
        Key: {
            pk: {
                S: `${entityType}#${uid}`,
            },
            sk: {
                S: `entity#${entityType}`,
            },
        },
    }), [entityType, uid])
    return useDynamoGetItem<T>(command, autoFetch);
}
/* skEntityType serve nei casi speciali in cui l'entityType nella sk sono diversi dal prefisso della pk.. Come ad esempio per ticket e contatti */
export function useMutationItem<T>(entityType: string, skEntityType: string = entityType) {
    const defaults = useMemo(() => ({ ReturnValues: "ALL_OLD", TableName }), []);
    const { error, mutate } = useDynamoMutation(defaults);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [item, setItem] = useState<T | undefined>(undefined)
    const mutateItem = useCallback(async (item: T & { uid?: string } & { lastUpdate?: string }& { tk?: string }) => {
        setIsLoading(true)
        if (!item.uid) {
            item.uid = createUID(32)
        }
        let Item = {
            
            pk: `${entityType}#${item.uid}`,
            sk: `entity#${skEntityType}`,
            details: {
                ...item
            },
            lastUpdate: formatISO(new Date())
        } as {
            pk: string,
            sk: string,
            tk?: string,
            details: T,
            lastUpdate: string
        }
        if(item?.tk) {
            Item = {...Item, tk: item.tk }
            // FIXME tk dentro item di device
            // delete Item.details?.tk
        }

        const ItemMMasrshalled = marshall({...Item}, {
            removeUndefinedValues: true,
            
        });
        await mutate({
            Item: ItemMMasrshalled,
        });
        const itemUnmarshalled = unmarshall(ItemMMasrshalled);
        const hashtagOccurency = itemUnmarshalled.sk.match(/#/g);
        
        const uid = giveMeTheUID(itemUnmarshalled.pk);
        let itemNew = {
            uid,
            ...itemUnmarshalled.details,
        };
        for (const [key, value] of Object.entries(itemUnmarshalled)) {
            /* specifications[key] = value */
            if (key !== "pk" && key !== "sk" && key !== "details") {
                itemNew[key] = value;
            }
        }
        let type = undefined;
        if (hashtagOccurency.length > 1) {
            type = giveMeTheType(itemUnmarshalled.sk);
        }
        delete itemUnmarshalled.sk;
        if (type) {
            itemNew.type = type;
        }
        if (itemUnmarshalled) {
            setItem(itemNew);
        }
        setIsLoading(false)

        return itemNew;
    }, [entityType, mutate, skEntityType]);
    return {
        error,
        isLoading,
        mutateItem,
        item
    }
}
export function useDeletionItem<T>(entityType: string, skEntityType: string = entityType) {
    const defaults = useMemo<Partial<DeleteItemCommandInput>>(() => ({ TableName, ReturnValues: 'NONE' }), []);
    const { error, isLoading, deletion } = useDynamoDelete(defaults);
    const deleteItem = useCallback(async (uid: string) => {
        const Key = marshall({

            pk: `${entityType}#${uid}`,
            sk: `entity#${skEntityType}`,
        });
        await deletion({
            Key,
        });
    }, [entityType, deletion, skEntityType]);
    return {
        error,
        isLoading,
        deleteItem,
    }
}