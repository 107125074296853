import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import { Alert, Button, Checkbox, Snackbar, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CategoryPicker } from "../../components/CategoryPicker";
import { DevicePicker } from "../../components/DevicePicker";
import { useManualGet } from "../../services/Dynamo/get";
import { useManualMutation } from "../../services/Dynamo/mutation";
import { CountryKey } from "../../utils/models/Common.model";
import { Manual } from "../../utils/models/Manual.model";
import { TabsLangManual } from "./pieces/TabsLangManual";
import { formatISO } from "date-fns";

export default function NewDownload() {
  const navigate = useNavigate();
  const { uidManual } = useParams();
  const isModify = uidManual !== "new";
  const {
    isLoading,
    item: manual,
    refetch,
  } = useManualGet(uidManual as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useManualMutation();

  const [langs, setLangs] = useState<CountryKey[]>([]);

  // campi
  const [name, setName] = useState("");
  // const [order, setOrder] = useState(0);
  const [checked, setChecked] = useState(false);
  const [devicesIds, setDevicesUid] = useState<Manual["devices_ids"]>([]);
  const [categoryIds, setCategoryUid] = useState<Manual["categories"]>([]);
  const [translations, setTranslations] = useState<Manual["translations"]>({});
  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
      };
  // const handleChangeNumber =
  //   (setter: Dispatch<SetStateAction<number>>) =>
  //   (event: ChangeEvent<HTMLInputElement>) => {
  //     setter(parseInt(event.target.value));
  //   };

  useEffect(() => {
    setName(manual?.name || "");
    // setOrder(manual?.order || 0);
    setChecked(manual?.published || false);
    setDevicesUid(manual?.devices_ids || []);
    setCategoryUid(manual?.categories || []);
    setTranslations(manual?.translations || {});
  }, [manual]);

  const handleSave = () => {
    let oldManual = manual || {};
    oldManual = {
      ...oldManual,
      name,
      published: checked,
      devices_ids: devicesIds,
      translations,
      categories: categoryIds,
    } as Manual
    if (isModify) {
      oldManual = {
        ...oldManual,
        updated_at: formatISO(new Date()),
      } as Manual
    } else {
      oldManual = {
        ...oldManual,
        created_at: formatISO(new Date()),
      }
    }
    mutateItem(oldManual as Manual).then((res) => {
      setShowNotificationSuccess(true)
      setTimeout(() => {
        setShowNotificationSuccess(false)
        if (isModify) {
          refetch();
        } else {
          navigate(`/manuals`);
        }
      }, 2000)
    });
  };

  if (!manual && isLoading === false && isModify) {
    navigate("../");
  }

  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">
              {isModify
                ? `Edit Manuals / Downloads: ${manual?.name}`
                : "New Manuals / Downloads"}
            </h1>
            <Button
              variant="contained"
              onClick={handleSave}
              className="buttons-style"
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
            <div>
              <div className="grid grid-rows-1 grid-cols-6 gap-2">
                <div className="col-span-5">
                  <p className="font-semibold">Name</p>
                  <div className="mt-1">
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      size="small"
                      type="text"
                      name="name"
                      value={name}
                      onChange={handleChangeString(setName)}
                    />
                  </div>
                </div>
                {/* <div className="col-span-1">
                  <p className="font-semibold">Order</p>
                  <div className="mt-1">
                    <TextField
                      fullWidth
                      label="Order"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="order"
                      value={order}
                      onChange={handleChangeNumber(setOrder)}
                    />
                  </div>
                </div> */}
                <div className="col-span-1">
                  <p className="font-semibold">Public</p>
                  <div className="mt-1">
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-rows-1 grid-cols-2 gap-2">
                <div>
                  <DevicePicker
                    selected={devicesIds}
                    onChange={setDevicesUid}
                    onChangeLangs={setLangs}
                  />
                </div>
                <div>
                  <CategoryPicker
                    selected={categoryIds}
                    onChange={setCategoryUid}
                  />
                </div>
              </div>
            </div>
          <div>
            <TabsLangManual
              langs={langs}
              translations={translations}
              setTranslations={setTranslations}
            />
          </div>
        </>
      )}
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
