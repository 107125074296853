import { Category } from '../../utils/models/Category.model';
import { TranslationEntity } from '../../utils/models/Common.model';
import { CustomerContactUs, RetailerContactUs } from '../../utils/models/ContactUs.model';
import { Device } from '../../utils/models/Device.model';
import { Faq } from '../../utils/models/Faq.model';
import { MailTemplate } from '../../utils/models/MailTemplate.model';
import { Manual } from '../../utils/models/Manual.model';
import { Progress } from '../../utils/models/Progress.model';
import { Retailer } from '../../utils/models/Retailer.model';
import { IssueAndSpecification } from '../../utils/models/Spec.model';
import { StoreBrand } from '../../utils/models/StoreBrand.model';
import { CustomerTicket, RetailerTicket } from '../../utils/models/Ticket.model';
import { TableName, useEntityGetItem } from './common';

/* GET  */
export const useDeviceGet = (uid: string, autoFetch = true) => useEntityGetItem<Device>('device', uid, autoFetch);
export const useFaqGet = (uid: string, autoFetch = true) => useEntityGetItem<Faq>('faq', uid, autoFetch);
export const useManualGet = (uid: string, autoFetch = true) => useEntityGetItem<Manual>('download', uid, autoFetch);
export const useCategoryGet = (uid: string, autoFetch = true) => useEntityGetItem<Category>('category', uid, autoFetch);
export const useCustomerContactUsGet = (uid: string, autoFetch = true) => useEntityGetItem<CustomerContactUs>('contactUs#customer', uid, autoFetch);
export const useRetailerContactUsGet = (uid: string, autoFetch = true) => useEntityGetItem<RetailerContactUs>('contactUs#retailer', uid, autoFetch);
export const useCustomerTicketFormGet = (uid: string, autoFetch = true) => useEntityGetItem<CustomerTicket>('ticketform#customer', uid, autoFetch);
export const useRetailerTicketFormGet = (uid: string, autoFetch = true) => useEntityGetItem<RetailerTicket>('ticketform#retailer', uid, autoFetch);
export const useStoreBrandGet = (uid: string, autoFetch = true) => useEntityGetItem<StoreBrand>('storebrand', uid, autoFetch);
export const useMailTemplateGet = (uid: string, autoFetch = true) => useEntityGetItem<MailTemplate>('mail_template', uid, autoFetch);
export const useIssueGet = (uid: string, autoFetch = true) => useEntityGetItem<IssueAndSpecification>('issue', uid, autoFetch);
export const useRetailerGet = (uid: string, autoFetch = true) => useEntityGetItem<Retailer>('retailer', uid, autoFetch);
export const useTranslationGet = (uid: string, autoFetch = true) => useEntityGetItem<TranslationEntity>('translation', uid, autoFetch);
export const useProgressGet = (uid: string, autoFetch = true) => useEntityGetItem<Progress>('progress', uid, autoFetch);
export { TableName };

