import { Box, Chip, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { useDeviceList } from "../services/Dynamo/query";
import { useLoader } from "../services/providers/LoaderProvider";
import { CountryKey } from "../utils/models/Common.model";
import { Device } from "../utils/models/Device.model";
import { LazyImage } from "./LazyImage";
const areArraysEquals = (a: unknown[], b: unknown[]) =>
  a.length === b.length && a.every((element, index) => element === b[index]);
const ITEM_HEIGHT = 90;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

export function DevicePicker({
  selected,
  onChange,
  onChangeLangs,
}: {
  selected: Device["uid"][];
  onChange: Dispatch<SetStateAction<string[]>>;
  onChangeLangs: (langs: CountryKey[]) => void;
}) {
  const { isLoading, items } = useDeviceList();
  const { setLoading } = useLoader("Devices");
  const [langs, setLangs] = useState<CountryKey[]>([]);
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  useEffect(() => {
    if (!items) {
      return;
    }
    const newLangsSet = items
      .filter((one) => selected.includes(one.uid))
      .reduce((acc, one) => {
        one.countries.forEach((el) => acc.add(el));
        return acc;
      }, new Set<CountryKey>());

    const newLangArr = [...newLangsSet];
    if (!areArraysEquals(newLangArr, langs)) {
      setLangs(newLangArr);
    }
  }, [selected, items, langs]);
  useEffect(() => {
    onChangeLangs(langs);
  }, [langs, onChangeLangs]);
  const handleChange = (event: SelectChangeEvent<string[]>) => {
    onChange(
      Array.isArray(event.target.value)
        ? event.target.value
        : [event.target.value]
    );
  };

  return (
    <div>
      {!isLoading && items && (
        <>
          <p className="font-semibold">Select Devices</p>
          <FormControl sx={{ my: 1, width: "100%" }} size="small">
            <InputLabel id="multiple-select-devices">
              Select Devices
            </InputLabel>
            <Select
              error={selected.length === 0}
              multiple
              value={selected}
              onChange={handleChange}
              input={<OutlinedInput label="Select Devices" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {items
                    .filter((one) => selected.includes(one.uid))
                    .map((one) => (
                      <Chip key={one.uid} label={one.title} />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {items?.map((device) => (
                <MenuItem value={device.uid} key={device.uid}>
                  <p className="mr-4">
                    <LazyImage
                      src={
                        device.assets?.find((one) => one.type === "device")
                          ?.path
                      }
                      alt="Immagine dispositivo"
                      width={100}
                      height={60}
                    />
                  </p>
                  <ListItemText
                    primary={device.title}
                    secondary={
                      <Fragment>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          cod. {device.code} {device.macroModel}
                        </Typography>
                      </Fragment>
                    }
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
}
