import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableProgress } from "../../components/TableProgress";
import { useProgressList } from "../../services/Dynamo/query";
import { useLoader } from "../../services/providers/LoaderProvider";

export default function Progress() {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useProgressList();
  const { setLoading } = useLoader("Requests");
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false)

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const onDelete = useCallback(() => {
    refetch()
    setShowNotificationSuccess(true)
    setTimeout(() => {
      setShowNotificationSuccess(false)
    }, 2000)
  }, [refetch])
  return (
    <div className="container mx-auto">
      <>
        <div className="flex justify-between items-center my-5">
          <h1 className="text-3xl">List of Progress</h1>
          <Button
            variant="contained"
            onClick={() => navigate("/progress/new")}
            className="buttons-style"
          >
            <AddCircleIcon className="icon-button" />
            <span className="w-full">add progress</span>
          </Button>
        </div>
        <TableProgress onDelete={onDelete} isLoading={isLoading} progress={items || []} />
      </>
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>
        </Snackbar>
      }
    </div>
  );
}
