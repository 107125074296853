import EditIcon from "@mui/icons-material/Edit";
import { Button, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeviceDeletion } from "../services/Dynamo/delete";
import { Device } from "../utils/models/Device.model";
import DeletionConfirm from "./DeletionConfirm";
import { LazyImage } from "./LazyImage";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function TableDevices({
  devices,
  isLoading,
  onDelete,
}: {
  devices: Device[];
  isLoading: boolean;
  onDelete: (row: Device) => void;
}) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [seletectRow, setSelecteRow] = useState<undefined | Device>(undefined);
  const handleClose = useCallback(() => {
    setDeleteModal(false);
  }, []);
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem,
  } = useDeviceDeletion();
  const deleteDevice = useCallback(() => {
    if (seletectRow) {
      deleteItem(seletectRow.uid).then((res) => {
        onDelete(seletectRow);
      });
      setDeleteModal(false);
    }
  }, [deleteItem, onDelete, seletectRow]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <>
      {deleteModal && seletectRow && (
        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this device: '${seletectRow.title}'?`}
          deletionMethod={deleteDevice}
        />
      )}
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table

          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {/* <StyledTableCell align="left">#</StyledTableCell> */}
              <StyledTableCell align="left">Image</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="center">Slug</StyledTableCell>
              <StyledTableCell align="center">Order</StyledTableCell>
              <StyledTableCell align="center">Used by</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          {isLoading && (
            <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
              <StyledTableCell align="center" colSpan={6}>
                Fetching data...
              </StyledTableCell>
            </StyledTableRow>
          )}
          {devices.length === 0 && !isLoading && (
            <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
              <StyledTableCell align="center" colSpan={6}>
                No Devices Availables.
              </StyledTableCell>
            </StyledTableRow>
          )}
          <TableBody>
            {
              devices
              .sort((a, b) => b.order - a.order)
                .filter((el, i) => (i >= page * rowsPerPage) && (i < (page + 1) * rowsPerPage))
                .map((row) => (
                  <StyledTableRow key={row.uid}>
                    {/* <StyledTableCell align="left">{index + 1}</StyledTableCell> */}
                    <StyledTableCell align="left">
                      <LazyImage
                        src={row.assets?.find((one) => one.type === "device")?.path}
                        alt="Immagine dispositivo"
                        width={100}
                        height={60}
                      />
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <p><strong>Title:</strong> {row.title}</p>
                      <p><strong>Model:</strong> {row.macroModel}</p>
                      <p><strong>Code:</strong> {row.code}</p>
                      {row.ean && (
                        <p><strong>EAN:</strong> {row.ean}</p>

                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.slug}</StyledTableCell>
                    <StyledTableCell align="center">{row.order}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.countries.join(", ")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="text"
                        onClick={() => navigate(`/devices/${row.uid}`)}
                        className="buttons-edit-delete-style mr-4"
                      >
                        <EditIcon />
                      </Button>
                      {/* <Button
                    variant="text"
                    onClick={() => {
                      setSelecteRow(row)
                      setDeleteModal(true)
                    }}
                    className="buttons-edit-delete-style"
                  >
                    <DeleteIcon />
                  </Button> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200, 500]}
        component="div"
        count={devices.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
