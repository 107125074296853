import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useIssueGet } from "../../services/Dynamo/get";
import { useSpecMutation } from "../../services/Dynamo/mutation";
import { CountryKey, countries } from "../../utils/models/Common.model";
import { Issue, IssueAndSpecification } from "../../utils/models/Spec.model";
import { TabsLangSpec } from "./pieces/TabsLangSpec";

export default function NewSpecIssues() {
  const navigate = useNavigate();
  const { uidSpec } = useParams();
  const isModify = uidSpec !== "new";
  const {
    isLoading,
    isStale,
    item: issue,
    refetch,
  } = useIssueGet(uidSpec as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useSpecMutation();

  const [name, setName] = useState<IssueAndSpecification["name"]>("");
  const [zendesk_specification, setZendesk_specification] =
    useState<IssueAndSpecification["zendesk_specification"]>("");
  const [issues, setIssues] = useState<IssueAndSpecification["issues"]>([]);
  const [translations, setTranslations] = useState<
    IssueAndSpecification["translations"]
  >({});
  const [order, setOrder] = useState<number>(0);

  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);
  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
  const handleChangeNumber =
    (setter: Dispatch<SetStateAction<number>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(parseInt(event.target.value));
    };
  useEffect(() => {
    setName(issue?.name || "");
    setZendesk_specification(issue?.zendesk_specification || "");
    setIssues(issue?.issues || []);
    setTranslations(issue?.translations || {});
    setOrder(issue?.order || 0);
  }, [issue]);
  const isFormValid = name !== "" && zendesk_specification !== "";
  const setIssueAtIndex = useCallback(
    (index: number, el: Issue) => {
      let tmpIssues = issues;
      tmpIssues[index] = el;
      setIssues(tmpIssues);
    },
    [issues]
  );
  const handleSave = () => {
    const oldSpec = issue || {};
    const newSpec = {
      ...oldSpec,
      name,
      issues,
      zendesk_specification,
      order
    } as IssueAndSpecification;
    mutateItem(newSpec).then((res) => {
      setShowNotificationSuccess(true);
      setTimeout(() => {
        setShowNotificationSuccess(false);
        if (isModify) {
          refetch();
        } else {
          navigate(`/specifications/${res.uid}`);
        }
      }, 2000);
    });
  };
  useEffect(() => {
    if (!issue && !isStale && !isLoading && isModify) {
      navigate("../");
    }
  }, [isLoading, isModify, isStale, navigate, issue]);

  const addIssue = useCallback(() => {
    let newIssues = [
      {
        macroModel: "Clem Phone / Clempad Call",
        rq_code: "",
        translations: {},
        zendesk_issue: "",
      },
      ...issues.slice(0),
    ] as Issue[];
    setIssues(newIssues);
  }, [issues]);
  const deleteAtIndex = useCallback(
    (issueIndex: number) => {
      const newIssues = [];
      for (let i = 0; i < issues.length; i++) {
        if (i !== issueIndex) {
          newIssues.push(issues[i]);
        }
      }
      setIssues(newIssues);
    },
    [issues]
  );
  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">
              {isModify ? `Edit issue: ${issue?.name}` : "New issue"}
            </h1>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={!isFormValid || isLoadinMut}
              className="buttons-style"
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
          <div className="grid grid-rows-1 grid-cols-6 gap-2">
            <div className="col-span-2">
              <p className="font-semibold">Name *</p>
              <div className="mt-1">
                <TextField
                  InputLabelProps={{
                    style: {
                      height: "56px",
                    },
                  }}
                  fullWidth
                  label="Name"
                  variant="outlined"
                  type="text"
                  value={name}
                  onChange={handleChangeString(setName)}
                />
              </div>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">Specification zendesk *</p>
              <div className="mt-1">
                <Autocomplete
                  clearIcon={false}
                  options={[]}
                  value={
                    zendesk_specification === ""
                      ? []
                      : zendesk_specification.split(" - ")
                  }
                  freeSolo
                  multiple
                  onChange={(e, newval, reason) => {
                    setZendesk_specification(newval.join(" - "));
                  }}
                  renderTags={(value, props) =>
                    value.map((option, index) => (
                      <Chip label={option} {...props({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      label="Add Specification zendesk(write the name and press enter)"
                      {...params}
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Order</p>
              <div className="mt-1">
                <TextField
                  InputLabelProps={{
                    style: {
                      height: "56px",
                    },
                  }}
                  fullWidth
                  label="Order"
                  variant="outlined"
                  type="number"
                  value={order}
                  onChange={handleChangeNumber(setOrder)}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-5">
            <Button
              variant="contained"
              onClick={addIssue}
              disabled={!isFormValid || isLoadinMut}
              className="buttons-style"
            >
              <AddIcon className="icon-button" />
              <span className="w-full">Add Issue</span>
            </Button>
          </div>
          <>
            {issues.length === 0 && (
              <div className="flex justify-center">No issues saved.</div>
            )}
            {!isLoading &&
              issues.map((one, index) => (
                <SingleIssue
                  key={index}
                  index={index}
                  issue={one}
                  setIssueAtIndex={setIssueAtIndex}
                  deleteAtIndex={deleteAtIndex}
                />
              ))}
          </>
        </>
      )}
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
function SingleIssue({
  issue,
  setIssueAtIndex,
  index,
  deleteAtIndex,
}: {
  issue: Issue;
  setIssueAtIndex: (index: number, issue: Issue) => void;
  index: number;
  deleteAtIndex: (index: number) => void;
}) {
  const langs: CountryKey[] = countries as CountryKey[];
  const [zendesk_issue, setZendesk_issue] =
    useState<Issue["zendesk_issue"]>("");
  const [macroModel, setmacroModel] = useState<Issue["macroModel"]>("Clempad");
  const [rq_code, setRq_code] = useState<Issue["rq_code"]>("");
  const [translations, setTranslations] = useState<Issue["translations"]>({});
  const [order, setOrder] = useState<number>(0);

  useEffect(() => {
    setZendesk_issue(issue.zendesk_issue);
    setmacroModel(issue.macroModel);
    setRq_code(issue?.rq_code);
    setTranslations(issue?.translations);
    setOrder(issue?.order || 0);
  }, [
    issue.macroModel,
    issue?.rq_code,
    issue?.translations,
    issue.zendesk_issue,
    issue.order,
  ]);
  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
    const handleChangeNumber =
    (setter: Dispatch<SetStateAction<number>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(parseInt(event.target.value));
    };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIssueAtIndex(index, {
        macroModel,
        rq_code,
        translations,
        zendesk_issue,
        order,
      });
    }, 1000);
    return () => clearTimeout(timeout);
  }, [zendesk_issue, macroModel, rq_code, translations, setIssueAtIndex, index, order]);
  return (
    <div className="mb-2 card">
      <div className="grid grid-cols-7 gap-2">
        <div className="col-span-6"></div>
        <div className="col-span-2">
          <p className="font-semibold">Issue zendesk *</p>
          <div className="mt-1">
            <TextField
              error={!zendesk_issue || zendesk_issue === ""}
              fullWidth
              label="Issue param for send in zendesk"
              variant="outlined"
              size="small"
              type="text"
              name="zendesk_issue"
              value={zendesk_issue}
              onChange={handleChangeString(setZendesk_issue)}
            />
          </div>
        </div>
        <div className="col-span-2">
          <p className="font-semibold">MacroModel *</p>
          <div className="mt-1">
            <FormControl fullWidth size="small">
              <InputLabel>(eg. Clempad) No selection for all</InputLabel>
              <Select
                required
                value={macroModel}
                error={!macroModel}
                name="macroModel"
                variant="outlined"
                label="(eg. Clempad) No selection for all"
                /* Lo so è bruttissimo */
                onChange={(e) => {
                  setmacroModel(
                    e.target.value as
                      | "Clempad"
                      | "Clemstation"
                      | "Clem Phone / Clempad Call"
                      | "My Clem Box"
                  );
                }}
              >
                <MenuItem value="Clempad">Clempad</MenuItem>
                <MenuItem value="Clemstation">Clemstation</MenuItem>
                <MenuItem value="Clem Phone / Clempad Call">
                  Clem Phone / Clempad Call
                </MenuItem>
                <MenuItem value="My Clem Box">My Clem Box</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-span-2">
          <p className="font-semibold">RQ Code *</p>
          <div className="mt-1">
            <TextField
              error={!rq_code || rq_code === ""}
              fullWidth
              label="RQ Code param"
              variant="outlined"
              size="small"
              type="text"
              name="rq_code"
              value={rq_code}
              onChange={handleChangeString(setRq_code)}
            />
          </div>
        </div>
        <div className="col-span-1">
          <p className="font-semibold">Order</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Order"
              variant="outlined"
              size="small"
              type="number"
              name="order"
              value={order}
              onChange={handleChangeNumber(setOrder)}
            />
          </div>
        </div>
      </div>
      <TabsLangSpec
        langs={langs}
        translations={translations}
        setTranslations={setTranslations}
      />
      <div className="flex justify-end">
        <Button
          variant="contained"
          onClick={() => {
            deleteAtIndex(index);
          }}
          className="button-delete !mt-5"
        >
          <DeleteIcon />
          <span className="w-full">delete</span>
        </Button>
      </div>
    </div>
  );
}
