import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSpecificationDeletion } from "../services/Dynamo/delete";
import { IssueAndSpecification } from "../utils/models/Spec.model";
import DeletionConfirm from "./DeletionConfirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableSpec({
  spec,
  isLoading,
  onDelete,
}: {
  spec: IssueAndSpecification[];
  isLoading: boolean;
  onDelete: (row: IssueAndSpecification) => void;
}) {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <StyledTableRow>
            {/* <StyledTableCell /> */}
            <StyledTableCell align="center">Lang</StyledTableCell>
            <StyledTableCell align="center">#</StyledTableCell>
            <StyledTableCell>Specification</StyledTableCell>
            <StyledTableCell align="center">Spec_zd</StyledTableCell>
            <StyledTableCell align="right">Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {spec.map((row) => (
            <SpecRow onDelete={onDelete} key={row.uid} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
function SpecRow(props: {
  row: IssueAndSpecification;
  onDelete: (row: IssueAndSpecification) => void;
}) {
  const navigate = useNavigate();
  const { row, onDelete } = props;
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClose = useCallback(() => {
    setDeleteModal(false);
  }, []);
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem,
  } = useSpecificationDeletion();
  const deleteSpecification = useCallback(() => {
    deleteItem(row.uid).then((res) => {
      onDelete(row);
    });
    setDeleteModal(false);
  }, [deleteItem, onDelete, row]);
  return (
    <>
      {deleteModal && (
        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this issue '${row.name} with 	Spec_zd ${row.zendesk_specification}'?`}
          deletionMethod={deleteSpecification}
        />
      )}
      <Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="center">{row.uid}</StyledTableCell>
          <StyledTableCell>{row.name}</StyledTableCell>
          <StyledTableCell align="center" sx={{ minWidth: "130px" }}>
            {row.zendesk_specification}
          </StyledTableCell>
          <StyledTableCell align="right" sx={{ minWidth: "176px" }}>
            <Button
              variant="text"
              onClick={() => navigate(`/specifications/${row.uid}`)}
              className="buttons-edit-delete-style mr-4"
            >
              <EditIcon />
            </Button>
            <Button
              variant="text"
              onClick={() => setDeleteModal(true)}
              className="buttons-edit-delete-style"
            >
              <DeleteIcon />
            </Button>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <List className="p-0">
                  {row?.translations === undefined && (
                    <Fragment key={row.uid}>
                      <ListItem>
                        <ListItemText
                          sx={{ textAlign: "center" }}
                          primary={"No Issues saved."}
                        />
                      </ListItem>
                    </Fragment>
                  )}
                  {row?.translations &&
                    Object.keys(row?.translations).map((language, i) => (
                      <Fragment key={language}>
                        {i > 0 && <Divider />}
                        <ListItem>
                          <ListItemText primary={language} />
                          <ListItemText
                            sx={{ textAlign: "rigth" }}
                            primary={row?.translations[language]}
                          />
                        </ListItem>
                      </Fragment>
                    ))}
                </List>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </Fragment>
    </>
  );
}
