import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRetailerGet } from "../../services/Dynamo/get";
import { useRetailerMutation } from "../../services/Dynamo/mutation";
import { useStoreBrandList } from "../../services/Dynamo/query";
import { useLoader } from "../../services/providers/LoaderProvider";
import { Retailer } from "../../utils/models/Retailer.model";
import { ulid } from "ulid";
import { sendEmail } from "../../services/SES/ses";
import { AwsContext } from "../../services/providers/AWSProvider";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function NewRetailerManagement() {
  const navigate = useNavigate();
  const { uidRetailer } = useParams();
  const isModify = uidRetailer !== "new";
  const { isLoading: soreBrandList, items } = useStoreBrandList();
  const { setLoading } = useLoader("StoreBrand");
  useEffect(() => {
    setLoading(soreBrandList);
  }, [soreBrandList, setLoading]);
  const {
    isLoading,
    item: retailer,
    isStale,
    refetch,
  } = useRetailerGet(uidRetailer as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useRetailerMutation();
  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);

  // campi
  const [country, setCountry] = useState("");
  const [storeBrand, setStoreBrand] = useState("");
  const [storeName, setStoreName] = useState("");
  const [vat, setVat] = useState("");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [postCode, setPostCode] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("");
  const [phone, setPhone] = useState("");

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
  const handleChangeSelect =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: SelectChangeEvent) => {
      setter(event.target.value as string);
    };
  const handleChangeCountry = (event: SelectChangeEvent) => {
    const selected = event.target.value as string;
    setCountry(selected);
  };

  const credentialProvider = useContext(AwsContext);
  const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);
  const [showNotificationFailed, setShowNotificationFailed] = useState<
    | {
        message: string;
      }
    | undefined
  >(undefined);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleActiveAndSendEmail = async () => {
    setAnchorEl(null);
    const failedRetailer: string[] = [];
    if (credentialProvider) {
      setIsSendingEmail(true);
      document.body.classList.add("hide-overlay-to-body");
          try {
            if (retailer) {
              /* AGGIORNO LO STATO */
              const oldRetailer = retailer || {};
              const newRetailer = {
                ...oldRetailer,
                status: "ACTIVE",
              } as Retailer;
              await mutateItem(newRetailer);
              /* MANDO LA MAIL */
              const successed = await sendEmail(
                "retailer-approved",
                retailer.country,
                retailer?.email,
                { CODE: retailer?.code as string },
                credentialProvider
              );
              if (successed === false) {
                failedRetailer.push(
                  `Store Brand ${retailer.store_brand}, Store Name ${retailer.store_name}, vat ${retailer.vat} from ${retailer.country}`
                );
              }
            }
          } catch (error: any) {
            setShowNotificationFailed({
              message: error?.message || "Error during sending email",
            });
            console.log(error);
          }
    } else {
      alert("No credentials");
    }
    if (failedRetailer.length > 0) {
      setShowNotificationFailed({
        message: `Error during send email to retailers: ${failedRetailer.join(
          "/"
        )}`,
      });
    }
    setIsSendingEmail(false);
    document.body.classList.remove("hide-overlay-to-body");
    refetch();
  };
  const handleActive = async () => {
    setAnchorEl(null);
    setIsSendingEmail(true);
    document.body.classList.add("hide-overlay-to-body");
        const oldRetailer = retailer || {};
        const newRetailer = {
          ...oldRetailer,
          status: "ACTIVE",
        } as Retailer;
        await mutateItem(newRetailer);

    setIsSendingEmail(false);
    document.body.classList.remove("hide-overlay-to-body");
    refetch();
  };
  const handlSendEmail = async () => {
    setAnchorEl(null);
    const failedRetailer: string[] = [];
    if (credentialProvider) {
      setIsSendingEmail(true);
      document.body.classList.add("hide-overlay-to-body");
          try {
            if (retailer) {
              const successed = await sendEmail(
                "retailer-approved",
                retailer?.country,
                retailer?.email,
                { CODE: retailer?.code as string },
                credentialProvider
              );
              if (successed === false) {
                failedRetailer.push(
                  `Store Brand ${retailer.store_brand}, Store Name ${retailer.store_name}, vat ${retailer.vat} from ${retailer.country}`
                );
              }
            }
          } catch (error: any) {
            setShowNotificationFailed({
              message: error?.message || "Error during sending email",
            });
            console.log(error);
          }
    } else {
      alert("No credentials");
    }
    if (failedRetailer.length > 0) {
      setShowNotificationFailed({
        message: `Error during send email to retailers: ${failedRetailer.join(
          "/"
        )}`,
      });
    }
    setIsSendingEmail(false);
    document.body.classList.remove("hide-overlay-to-body");
  };

  useEffect(() => {
    setCountry(retailer?.country || "");
    setStoreBrand(retailer?.store_brand || "");
    setStoreName(retailer?.store_name || "");
    setVat(retailer?.vat || "");
    setEmail(retailer?.email || "");
    setAddress1(retailer?.address1 || "");
    setAddress2(retailer?.address2 || "");
    setAddress3(retailer?.address3 || "");
    setPostCode(retailer?.post_code || "");
    setProvince(retailer?.province || "");
    setCity(retailer?.city || "");
    setStatus(retailer?.status || "");
    setPhone(retailer?.phone || "");
  }, [retailer]);

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const oldRetailer = retailer || { code: `${vat}-${ulid()}` };
    mutateItem({
      ...oldRetailer,
      country,
      store_brand: storeBrand,
      store_name: storeName,
      vat,
      email,
      address1,
      address2,
      address3,
      post_code: postCode,
      province,
      city,
      status,
      phone,
    } as Retailer).then((res) => {
      setShowNotificationSuccess(true);
      setTimeout(() => {
        setShowNotificationSuccess(false);
        if (isModify) {
          refetch();
        } else {
          navigate(`/retailer-management/${res.uid}`);
        }
      }, 2000);
    });
  };

  if (!retailer && !isStale && isLoading === false && isModify) {
    navigate("../");
  }
  const isFormValid =
    country !== "" &&
    storeName !== "" &&
    storeBrand !== "" &&
    vat !== "" &&
    email !== "" &&
    address1 !== "" &&
    postCode !== "" &&
    city !== "" &&
    status !== "" &&
    phone !== "";
  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <form onSubmit={handleSave}>
          <Link to=".." relative="path">
            <Button className="buttons-style" variant="contained" type="button">
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between items-center my-4">
            <h1 className="text-3xl">
              {isModify ? `Edit Retailer: ${retailer?.email}` : "New Retailer"}
            </h1>

            <Button
              variant="contained"
              type="submit"
              disabled={!isFormValid || isLoadinMut}
              className="buttons-style"
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
          {isModify && (
            <div className="flex justify-between items-center my-4">
              <h1 className="text-2xl">{`code: ${retailer?.code}`}</h1>
              <div>
                <Button
                  sx={{ border: "!1px solid red !important" }}
                  className="!ml-3 buttons-style-variant"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MoreVertIcon className="icon-button" />
                  <span className="w-full">Options</span>
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    disabled={retailer?.status === "ACTIVE" ? true : false}
                    onClick={handleActiveAndSendEmail}
                  >
                    Activate retailer and send mail
                  </MenuItem>
                  <MenuItem
                    disabled={retailer?.status === "ACTIVE" ? true : false}
                    onClick={handleActive}
                  >
                    Activate retailer
                  </MenuItem>
                  <MenuItem onClick={handlSendEmail}>
                    Send mail to retailer
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )}
          <div className="grid grid-rows-1 grid-cols-2 gap-2">
            <div>
              <p className="font-semibold">Country *</p>
              <div className="mt-1">
                <FormControl fullWidth size="small" disabled={isModify}>
                  <InputLabel>Country</InputLabel>
                  <Select
                    error={!country || country === ""}
                    required
                    value={country}
                    label="Type"
                    onChange={handleChangeCountry}
                  >
                    <MenuItem value="it">it</MenuItem>
                    <MenuItem value="fr">fr</MenuItem>
                    <MenuItem value="es">es</MenuItem>
                    <MenuItem value="pt">pt</MenuItem>
                    <MenuItem value="de">de</MenuItem>
                    <MenuItem value="pl">pl</MenuItem>
                    <MenuItem value="be">be</MenuItem>
                    <MenuItem value="nl">nl</MenuItem>
                    <MenuItem value="tr">tr</MenuItem>
                    <MenuItem value="au">au</MenuItem>
                    <MenuItem value="ch">ch</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div>
              <p className="font-semibold">StoreBrand *</p>
              <div className="mt-1">
                <FormControl fullWidth size="small">
                  <InputLabel>StoreBrand</InputLabel>
                  <Select
                    required
                    value={storeBrand}
                    error={!storeBrand}
                    name="storeBrand"
                    variant="outlined"
                    label="StoreBrand"
                    onChange={(e) => {
                      setStoreBrand(e.target.value);
                    }}
                  >
                    {items?.map((el) => {
                      return (
                        <MenuItem key={el.uid} value={el.uid}>
                          {el.name}
                        </MenuItem>
                      );
                    })}
                    <MenuItem value="Clempad">Clempad</MenuItem>
                    <MenuItem value="Clemstation">Clemstation</MenuItem>
                    <MenuItem value="Clem Phone / Clempad Call">
                      Clem Phone / Clempad Call
                    </MenuItem>
                    <MenuItem value="My Clem Box">My Clem Box</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 gap-2 mt-4">
            <div>
              <p className="font-semibold">Store Name *</p>
              <div className="mt-1">
                <TextField
                  error={!storeName || storeName === ""}
                  fullWidth
                  required
                  label="Store Name"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="storeName"
                  value={storeName}
                  onChange={handleChangeString(setStoreName)}
                />
              </div>
            </div>
            <div>
              <p className="font-semibold">Vat number *</p>
              <div className="mt-1">
                <TextField
                  error={!vat || vat === ""}
                  fullWidth
                  required
                  label="Vat number"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="vat"
                  value={vat}
                  onChange={handleChangeString(setVat)}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 gap-2 mt-4">
            <div>
              <p className="font-semibold">Email *</p>
              <div className="mt-1">
                <TextField
                  error={!email || email === ""}
                  fullWidth
                  required
                  label="Email"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleChangeString(setEmail)}
                />
              </div>
            </div>
            <div>
              <p className="font-semibold">Address 1 *</p>
              <div className="mt-1">
                <TextField
                  error={!address1 || address1 === ""}
                  fullWidth
                  required
                  label="Address 1"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address1"
                  value={address1}
                  onChange={handleChangeString(setAddress1)}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 gap-2 mt-4">
            <div>
              <p className="font-semibold">Address 2</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Address 2"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address2"
                  value={address2}
                  onChange={handleChangeString(setAddress2)}
                />
              </div>
            </div>
            <div>
              <p className="font-semibold">Address 3</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Address 3"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="address3"
                  value={address3}
                  onChange={handleChangeString(setAddress3)}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 gap-2 mt-4">
            <div>
              <p className="font-semibold">Post Code *</p>
              <div className="mt-1">
                <TextField
                  error={!postCode || postCode === ""}
                  fullWidth
                  required
                  label="Post Code"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="postCode"
                  value={postCode}
                  onChange={handleChangeString(setPostCode)}
                />
              </div>
            </div>
            <div>
              <p className="font-semibold">Province</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Province"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="province"
                  value={province}
                  onChange={handleChangeString(setProvince)}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 gap-2 mt-4">
            <div>
              <p className="font-semibold">City *</p>
              <div className="mt-1">
                <TextField
                  error={!city || city === ""}
                  fullWidth
                  required
                  label="City"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="city"
                  value={city}
                  onChange={handleChangeString(setCity)}
                />
              </div>
            </div>
            <div>
              <p className="font-semibold">Status *</p>
              <div className="mt-1">
                <FormControl fullWidth size="small">
                  <InputLabel>Status *</InputLabel>
                  <Select
                    error={!status || status === ""}
                    required
                    value={status}
                    label="Status"
                    onChange={handleChangeSelect(setStatus)}
                  >
                    <MenuItem value="PENDING">PENDING</MenuItem>
                    <MenuItem value="ACTIVE">ACTIVE</MenuItem>
                    <MenuItem value="SUSPENDED">SUSPENDED</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-2 gap-2 mt-4">
            <div>
              <p className="font-semibold">Phone *</p>
              <div className="mt-1">
                <TextField
                  error={!phone || phone === ""}
                  fullWidth
                  required
                  label="Phone"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChangeString(setPhone)}
                />
              </div>
            </div>
            <div></div>
          </div>
        </form>
      )}
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
      {showNotificationFailed && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={10000}
          onClose={() => {
            setShowNotificationFailed(undefined);
          }}
          message={showNotificationFailed.message}
        >
          <Alert
            className="!text-white"
            sx={{ color: "white", backgroundColor: "red" }}
            severity="error"
          >
            {showNotificationFailed.message}
          </Alert>
        </Snackbar>
      )}
      {isSendingEmail && (
        <div className="blocker-overlay">
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress />
            <div className="text-white">Operation in progress</div>
          </div>
        </div>
      )}
    </div>
  );
}
