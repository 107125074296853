import { SESv2Client, SendEmailCommandInput, SendEmailCommand, GetEmailTemplateCommand } from "@aws-sdk/client-sesv2";
import { CognitoIdentityCredentialProvider } from "@aws-sdk/credential-providers";
/* import fetch from 'node-fetch'; */

const region = process.env.REACT_APP_REGION

// 👇 Uploading the file using the fetch API to the server
export async function sendEmail(template: string, lang: string, email: string, data: { [key: string]: string }, credentials: CognitoIdentityCredentialProvider): Promise<boolean> {
  const sesClient = new SESv2Client({ region, credentials });
  const exist = await checkIfTemplateExist(template, lang, credentials)
  if (!exist) {
    throw new Error('template email not found')
  }
  const ToAddresses =  [email]
  const FromEmailAddress = process.env.REACT_APP_FROM_EMAIL_ADDRESS
  const input: SendEmailCommandInput = {
    FromEmailAddress,
    Destination: {
      ToAddresses,
    },
    Content: {
      Template: {
        TemplateName: `${template}-${process.env.REACT_APP_STAGE}-${lang}`,
        TemplateData: JSON.stringify(data),

      },
    },
  };
  try {
    const response = await sesClient.send(new SendEmailCommand(input));
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}
export async function checkIfTemplateExist(teplatePrefix: string, templateLang: string, credentials: CognitoIdentityCredentialProvider): Promise<boolean> {
  const sesClient = new SESv2Client({ region, credentials });
  const input = {
    TemplateName: `${teplatePrefix}-${process.env.REACT_APP_STAGE}-${templateLang}`,
  };
  const command = new GetEmailTemplateCommand(input);
  try {
    const response = await sesClient.send(command);
    return response?.TemplateContent !== undefined

  } catch (error) {
    console.log(error)
    throw new Error('Error getting the template')
  }
}