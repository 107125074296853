import { Modal, Box, Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '11px',
    boxShadow: 24,
    p: 4,
    minWidth: "30%"
};
export default function DeletionConfirm({ hederTitle, message, deletionMethod, handleClose }: { hederTitle?: string, message: string, deletionMethod: () => void; handleClose: () => void }) {
    if (!hederTitle) hederTitle = "Are you sure you want to continue?"
    return <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
                {hederTitle}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {message}
            </Typography>
            <div className="flex justify-between">

                <Button
                    variant="contained"
                    onClick={handleClose}
                    className="button-close !mt-5"
                >
                    <CloseIcon />
                    <span className="w-full">close</span>
                </Button>
                <Button
                    variant="contained"
                    onClick={deletionMethod}
                    className="button-delete !mt-5"
                >
                    <DeleteIcon />
                    <span className="w-full">delete</span>
                </Button>
            </div>
        </Box>
    </Modal>
}