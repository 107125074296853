import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreBrandList } from "../../services/Dynamo/query";
import { useLoader } from "../../services/providers/LoaderProvider";
import { TableStoreBrand } from "../../components/TableStoreBrand";

export default function StoreBrand() {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useStoreBrandList();
  const { setLoading } = useLoader("StoreBrand");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);

  const onDelete = useCallback(() => {
    refetch();
    setShowNotificationSuccess(true);
    setTimeout(() => {
      setShowNotificationSuccess(false);
    }, 2000);
  }, [refetch]);
  return (
    <div className="container mx-auto">
      <>
        <div className="flex justify-between items-center my-5">
          <h1 className="text-3xl">List of Storebrand</h1>
          <Button
            variant="contained"
            onClick={() => navigate("/store-brand/new")}
            className="buttons-style"
          >
            <AddCircleIcon className="icon-button" />
            <span className="w-full">add storebrand</span>
          </Button>
        </div>
        <TableStoreBrand
          onDelete={onDelete}
          isLoading={isLoading}
          storeBrands={items || []}
        />
      </>
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
