import { Category } from 'aws-sdk/clients/cloudformation';
import { TranslationEntity } from '../../utils/models/Common.model';
import { Device } from '../../utils/models/Device.model';
import { Faq } from '../../utils/models/Faq.model';
import { Manual } from '../../utils/models/Manual.model';
import { Progress } from '../../utils/models/Progress.model';
import { IssueAndSpecification } from '../../utils/models/Spec.model';
import { StoreBrand } from '../../utils/models/StoreBrand.model';
import { RetailerTicket } from '../../utils/models/Ticket.model';
import { useDeletionItem } from './common';
export function useDeviceDeletion() {
    return useDeletionItem<Device>('device')
}
export function useFaqDeletion() {
    return useDeletionItem<Faq>('faq')
}
export function useManualDeletion() {
    return useDeletionItem<Manual>('download')
}
export function useCategoryDeletion() {
    return useDeletionItem<Category>('category')
}
// export function useCustomerContactUsDeletion() {
//     return useDeletionItem<CustomerTicket>('ticketform', 'ticketform#customer')
// }
// export function useRetailerContactUsDeletion() {
//     return useDeletionItem<RetailerTicket>('ticketform', 'ticketform#retailer')
// }
export function useSpecificationDeletion() {
    return useDeletionItem<IssueAndSpecification>('issue')
}
export function useStoreBrandDeletion() {
    return useDeletionItem<StoreBrand>('storebrand')
}
export function useRetailerDeletion() {
    return useDeletionItem<RetailerTicket>('retailer')
}
export function useTranslationDeletion() {
    return useDeletionItem<TranslationEntity>('translation')
}
export function useProgressDeletion() {
    return useDeletionItem<Progress>('progress')
}