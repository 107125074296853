import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { format, parseISO } from "date-fns";
import { Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useManualDeletion } from "../services/Dynamo/delete";
import { DATE_DISPLAY } from "../utils/interface";
import { CountryKey } from "../utils/models/Common.model";
import { Manual } from "../utils/models/Manual.model";
import DeletionConfirm from "./DeletionConfirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Row(props: { row: Manual; onDelete: (row: Manual) => void }) {
  const navigate = useNavigate();
  const { row, onDelete } = props;
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  let country: CountryKey;
  let data: string | undefined;
  if ("it" in row.translations) {
    country = "it";
    data = row.translations[country]?.created_at;
  } else {
    const lang = Object.keys(row.translations)[0] as CountryKey;
    country = lang;
    data = row.translations[country]?.created_at;
  }

  const handleClose = useCallback(() => {
    setDeleteModal(false);
  }, []);
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem,
  } = useManualDeletion();
  // TODO cancellare anche il file su s3
  const deleteManual = useCallback(() => {
    deleteItem(row.uid).then((res) => {
      onDelete(row);
    });
    setDeleteModal(false);
  }, [deleteItem, onDelete, row]);

  return (
    <>
      {deleteModal && (
        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this manual '${row.name}'?`}
          deletionMethod={deleteManual}
        />
      )}
      <Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="center">{row.uid}</StyledTableCell>
          <StyledTableCell align="center">{country}</StyledTableCell>
          <StyledTableCell>{row.name}</StyledTableCell>
          <StyledTableCell align="center" sx={{ minWidth: "130px" }}>
            {data ? format(parseISO(data), DATE_DISPLAY) : ""}
          </StyledTableCell>
          <StyledTableCell align="center" sx={{ minWidth: "176px" }}>
            <Button
              variant="text"
              onClick={() => navigate(`/manuals/${row.uid}`)}
              className="buttons-edit-delete-style mr-4"
            >
              <EditIcon />
            </Button>
            <Button
              variant="text"
              onClick={() => setDeleteModal(true)}
              className="buttons-edit-delete-style"
            >
              <DeleteIcon />
            </Button>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <List className="p-0">
                  {(!row?.translations ||
                    Object.keys(row?.translations).length === 0) && (
                    <Fragment key={row.uid}>
                      <ListItem>
                        <ListItemText
                          sx={{ textAlign: "center" }}
                          primary={"No translataions saved."}
                        />
                      </ListItem>
                    </Fragment>
                  )}
                  {row?.translations &&
                    Object.keys(row.translations).map((trans, i) => (
                      <Fragment key={trans || ""}>
                        {i > 0 && <Divider />}
                        <ListItem>
                          <ListItemText
                            primary={row.translations[trans]?.country || ""}
                          />
                          <ListItemText
                            primary={row.translations[trans]?.title || ""}
                          />
                          <ListItemText
                            sx={{ textAlign: "right" }}
                            primary={
                              row.translations[trans]?.created_at
                                ? format(
                                    parseISO(
                                      row.translations[trans]?.created_at!
                                    ),
                                    DATE_DISPLAY
                                  )
                                : ""
                            }
                          />
                        </ListItem>
                      </Fragment>
                    ))}
                </List>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </Fragment>
    </>
  );
}

export default function TableManuals({
  manuals,
  isLoading,
  onDelete,
}: {
  manuals: Manual[];
  isLoading: boolean;
  onDelete: (row: Manual) => void;
}) {
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell align="center">#</StyledTableCell>
            <StyledTableCell align="center">Lang</StyledTableCell>
            <StyledTableCell>Manual</StyledTableCell>
            <StyledTableCell align="center">Date</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        {isLoading && (
          <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <StyledTableCell align="center" colSpan={6}>
              Fetching data...
            </StyledTableCell>
          </StyledTableRow>
        )}
        {manuals.length === 0 && !isLoading && (
          <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <StyledTableCell align="center" colSpan={6}>
              No Manuals Availables.
            </StyledTableCell>
          </StyledTableRow>
        )}
        <TableBody>
          {manuals
            .sort(
              (a, b) =>
                parseISO(b.created_at).getTime() -
                parseISO(a.created_at).getTime()
            )
            .map((row) => (
              <Row key={row.uid} row={row} onDelete={onDelete} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
