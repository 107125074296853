import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import {TablePagination, Table, Paper, TextField} from "@mui/material";

import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { StoreBrand } from "../utils/models/StoreBrand.model";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useStoreBrandDeletion } from "../services/Dynamo/delete";
import DeletionConfirm from "./DeletionConfirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function TableStoreBrand({
  storeBrands,
  isLoading,
  onDelete,
}: {
  storeBrands: StoreBrand[];
  isLoading: boolean;
  onDelete: (row: StoreBrand) => void;
}) {
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);
  const [seletectRow, setSelecteRow] = useState<undefined | StoreBrand>(undefined)
  const handleClose = useCallback(() => {
    setDeleteModal(false);
  }, []);
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem,
  } = useStoreBrandDeletion();
  const deleteStoreBrand = useCallback(() => {
    if(seletectRow){

      deleteItem(seletectRow.uid).then((res) => {
        onDelete(seletectRow);
      });
      setDeleteModal(false);
    }
  }, [deleteItem, onDelete, seletectRow]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const [currentSearch, setCurrentSearch] = useState<string>("")
  const [filterdItems, setFilteredItems] = useState<StoreBrand[]>([])
  useEffect(() => {
    const timeout = setTimeout(() => {
      const filteredItems = (storeBrands || [])?.filter((el) => {
        return el.name?.toLowerCase().includes(currentSearch.toLowerCase()) ||
          el.countries.join(' ')?.toLowerCase().includes(currentSearch.toLowerCase())
      })
      setFilteredItems(filteredItems)
    }, 1000);
    return () => clearTimeout(timeout)
  }, [currentSearch, storeBrands])
  return (
    <>
      {deleteModal && seletectRow && (
        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this Store Brand: '${seletectRow.name}'?`}
          deletionMethod={deleteStoreBrand}
        />
      )}
      <div className="my-3">
        <p className="font-semibold">Insert search terms</p>
        <div className="mt-1">
          <TextField
            fullWidth
            label="Insert search terms"
            variant="outlined"
            size="small"
            type="text"
            name="title"
            value={currentSearch}
            onChange={(e) => { setCurrentSearch(e.target.value) }}
          />
        </div>
      </div>
    <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
      <Table
        
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="left">#</StyledTableCell>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="center">Used by</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        {isLoading && (
            <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
              <StyledTableCell align="center" colSpan={4}>
                Fetching data...
              </StyledTableCell>
            </StyledTableRow>
          )}
          {filterdItems.length === 0 && !isLoading && (
            <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
              <StyledTableCell align="center" colSpan={4}>
                No Store Brands Availables.
              </StyledTableCell>
            </StyledTableRow>
          )}
        <TableBody>
          {filterdItems
          .filter((el, i) => (i >= page * rowsPerPage) && (i < (page + 1) * rowsPerPage))
          .map((row) => (
            <StyledTableRow key={row.uid}>
              <StyledTableCell align="left">{row.uid}</StyledTableCell>
              <StyledTableCell component="th" scope="row"><p>{row.name}</p></StyledTableCell>
              <StyledTableCell align="center">{row.countries.join(', ')}</StyledTableCell>
              <StyledTableCell align="center">
                <Button
                  variant="text"
                  onClick={() => navigate(`/store-brand/${row.uid}`)}
                  className="buttons-edit-delete-style mr-4"
                >
                  <EditIcon />
                </Button>
                <Button
                  variant="text"
                  onClick={() => {
                    setSelecteRow(row)
                    setDeleteModal(true)
                  }}
                  className="buttons-edit-delete-style"
                >
                  <DeleteIcon />
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
        rowsPerPageOptions={[50, 100, 200, 500]}
        component="div"
        count={storeBrands.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
