import { Skeleton } from "@mui/material";
import {
  DetailedHTMLProps,
  ImgHTMLAttributes,
  ReactEventHandler,
  useState,
} from "react";

export function LazyImage({
  width,
  height,
  src,
  alt,
  className,
  style,
}: {
  width?: string | number | undefined;
  height?: string | number | undefined;
  src?: string;
  alt?: string;
  className?: string;
  style?: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >;
}) {
  const [lastLoaded, setLastLoaded] = useState<string | undefined>(undefined);
  const stopLoading: ReactEventHandler<HTMLImageElement> = (e) => {
    setLastLoaded(e.currentTarget.src);
  };
  // useEffect(() => {
  //   // Initiating Intersection Observer
  //   const observer = new IntersectionObserver((entries) => {
  //     // Set actual image source && unobserve when intersecting
  //     if (entries[0].isIntersecting) {
  //       setView(src);
  //       observer.unobserve(placeholderRef.current);
  //     }
  //   });

  //   // observe for an placeholder image
  //   if (placeholderRef && placeholderRef.current) {
  //     observer.observe(placeholderRef.current);
  //   }
  // }, [src]);
  const isLoading = lastLoaded !== src;
  return (
    <>
      {(isLoading || !src) && (
        <Skeleton variant="rectangular" animation="wave" width={width || '100%' } height={height || '60px'} />
      )}
      <img
        src={src} // Gets src only when placeholder intersecting
        className={className}
        style={isLoading ? { display: "none" } : style}
        alt={alt}
        height={height}
        width={width}
        onLoad={stopLoading}
      />
    </>
  );
}
