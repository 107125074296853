export enum Country {
    it = 'it',
    fr = 'fr',
    es = 'es',
    pt = 'pt',
    de = 'de',
    pl = 'pl',
    // bl_nl = 'bl_nl',
    tr = 'tr',
    be = 'be',
    nl = 'nl',
}
export const countries = ['it','fr','es','pt','de','pl','tr','be','nl']
export type CountryKey = keyof typeof Country

export enum Status {
    'PENDING',
    'ACTIVE',
    'SUSPENDED',
}
export type Asset = {
    url: string
}
export type GenericTranslations = {
    [key: string]: string
}
export type GenericTranslationsKeysLang = {
    [key in CountryKey]?: string
}
export type TranslationEntity = {
    uid: string,
    legacyId: number,
    defaultMessage?: string
    description?: string
    label: string
    translations: {
        [key: string] : TranslationElement
    }
}

export type TranslationElement = {
    description?: string
    translation?: string
}