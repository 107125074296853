import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SelectContactUs() {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto">
      <div className="flex justify-between items-center">
        <h1 className="text-3xl">Select Customize Contact Us form type:</h1>
        <div className="flex">
          <Button
            variant="contained"
            onClick={() => navigate("customer")}
            className="buttons-style !mr-2"
          >
            <span className="w-full">customer</span>
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate("retailer")}
            className="buttons-style"
          >
            <span className="w-full">retailer</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
