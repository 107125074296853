import SaveIcon from "@mui/icons-material/Save";
import { Alert, Box, Button, Checkbox, Snackbar, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCustomerTicketFormMutation } from "../../../services/Dynamo/mutation";
import { useCustomerTicketFormList } from "../../../services/Dynamo/query";
import { useLoader } from "../../../services/providers/LoaderProvider";
import { langs } from "../../../utils/interface";
import { Country, CountryKey } from "../../../utils/models/Common.model";
import { CustomerTicket } from "../../../utils/models/Ticket.model";

export function TabsLangTicketFormCustomer() {
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false);
  const [value, setValue] = useState<undefined | CountryKey>(langs[0]);
  const {
    isLoading,
    items: itemCustomer,
    refetch,
  } = useCustomerTicketFormList();
  const { setLoading } = useLoader("TicketForm");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const {
    isLoading: isLoadingMutCustomer,
    error: errorMutCustomer,
    mutateItem: mutateItemCustomer,
  } = useCustomerTicketFormMutation(); // FIXME
  const onSave = useCallback(
    (element: CustomerTicket) => {
      mutateItemCustomer(element).then(() => {
        setShowNotificationSuccess(true);
        setTimeout(() => {
          setShowNotificationSuccess(false);
          refetch();
        }, 2000);
      });
    },
    [mutateItemCustomer, refetch]
  );
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value || false}
            onChange={(_, value) => setValue(value)}
            aria-label="basic tabs example"
          >
            {langs.map((key) => (
              <Tab key={key} label={Country[key]} value={key} />
            ))}
          </Tabs>
        </Box>
        {langs.map((key) => (
          <SingleElement
            lang={key}
            selectedLang={value}
            ticketForm={itemCustomer?.find((ticket) => ticket.country === key)}
            onSave={onSave}
          ></SingleElement>
        ))}
      </Box>
      {showNotificationSuccess &&
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert sx={{ color: "white", backgroundColor: "#1D4EBB" }} severity="info">
            Operation carried out successfully
          </Alert>

        </Snackbar>
      }
    </div>
  );
}

function SingleElement({
  lang,
  selectedLang,
  ticketForm,
  onSave,
}: {
  lang: string;
  selectedLang?: string;
  ticketForm?: CustomerTicket;
  onSave: (ticketForm: CustomerTicket) => void;
}) {
  const [address3, setAddress3] = useState<boolean>(false);
  const [personalNumber, setPersonalNumber] = useState<boolean>(false);
  const [province, setProvince] = useState<boolean>(false);

  useEffect(() => {
    setAddress3(ticketForm?.enable_address_3 || false);
    setPersonalNumber(ticketForm?.enable_personal_number || false);
    setProvince(ticketForm?.enable_province || false);
  }, [ticketForm]);

  const handleSaveCustomer = () => {
    onSave({
      ...ticketForm,
      enable_address_3: address3,
      enable_personal_number: personalNumber,
      enable_province: province,
    } as CustomerTicket);
  };

  return (
    <>
      <div role="tabpanel" hidden={selectedLang !== lang}>
        <div className="container mx-auto">
          <div className="grid grid-cols-3 grid-rows-1 gap-4 mt-4">
            <div className="flex items-center">
              <div className="mt-1">
                <Checkbox
                  checked={address3}
                  onChange={(event) => {
                    setAddress3(event.target.checked)
                    setProvince(!event.target.checked)
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <p className="font-semibold">Address 3</p>
            </div>
            
            <div className="flex items-center">
              <div className="mt-1">
                <Checkbox
                  checked={province}
                  onChange={(event) => {
                    setProvince(event.target.checked)
                    setAddress3(!event.target.checked)
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <p className="font-semibold">Province</p>
            </div>
            <div className="flex items-center">
              <div className="mt-1">
                <Checkbox
                  checked={personalNumber}
                  onChange={(event) => setPersonalNumber(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
              <p className="font-semibold">Personal number</p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="contained"
            onClick={handleSaveCustomer}
            className="buttons-style"
          >
            <SaveIcon className="icon-button" />
            <span className="w-full">Save Language {selectedLang}</span>
          </Button>
        </div>
      </div>
    </>
  );
}
