import {
  AttributeValue,
  DeleteItemCommand,
  DeleteItemCommandInput,
  DynamoDBClient,
  GetItemCommand,
  GetItemCommandInput,
  PutItemCommand,
  PutItemCommandInput,
  PutItemCommandOutput,
  QueryCommand,
  QueryCommandInput,
} from "@aws-sdk/client-dynamodb";
import {
  CognitoIdentityCredentialProvider,
  FromCognitoIdentityPoolParameters,
  fromCognitoIdentityPool,
} from "@aws-sdk/credential-providers";
import { unmarshall } from "@aws-sdk/util-dynamodb";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
export type AWSContextProps = CognitoIdentityCredentialProvider;
const region = process.env.REACT_APP_REGION;
export const AwsContext = createContext<AWSContextProps | undefined>(undefined);
const fromCognitoIdentityPoolParams: FromCognitoIdentityPoolParameters = {
  // Required. The unique identifier for the identity against which credentials
  // will be issued.
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL as string,
  logins: {
    // [process.env.REACT_APP_COGNITO_ID_PROVIDER as string]: idToken,
  },
  clientConfig: {
    region,
  },
};
export function AWSProvider({
  idToken,
  children,
}: PropsWithChildren<{
  idToken: string;
}>) {
  const value = useMemo<AWSContextProps | undefined>(() => {
    if (!idToken) {
      return undefined;
    }
    const credentialProvider = fromCognitoIdentityPool({
      ...fromCognitoIdentityPoolParams,
      logins: {
        [process.env.REACT_APP_COGNITO_ID_PROVIDER as string]: idToken,
      },
    });
    return credentialProvider;
  }, [idToken]);
  return <AwsContext.Provider value={value}>{children}</AwsContext.Provider>;
}

export function useDynamoQuery<T>(
  command: QueryCommandInput,
  {
    autoLoadAll,
  }: {
    autoLoadAll: boolean;
  } = {
    autoLoadAll: false,
  }
) {
  const credentialProvider = useContext(AwsContext);
  const [items, setItems] = useState<T[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(true);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<
    Record<string, AttributeValue> | undefined
  >(undefined);
  const client = useMemo(
    () =>
      new DynamoDBClient({
        region,
        credentials: credentialProvider,
      }),
    [credentialProvider]
  );
  const executeCommand = useCallback(
    async (
      command: QueryCommandInput,
      abortSignal?: AbortSignal | undefined
    ) => {
      const res = await client?.send(
        new QueryCommand({
          ...command,
        }),
        { abortSignal }
      );
      const itemsUnmarshall = res?.Items?.map(
        (el: Record<string, AttributeValue>) => {
          const itemUnmarshalled = unmarshall(el);
          const hashtagOccurency = itemUnmarshalled.sk.match(/#/g);
          const uid = giveMeTheUID(itemUnmarshalled.pk);
          let item = {
            uid,
            ...itemUnmarshalled.details,
          };
          for (const [key, value] of Object.entries(itemUnmarshalled)) {
            /* specifications[key] = value */
            if (key !== "pk" && key !== "sk" && key !== "details") {
              item[key] = value;
            }
          }
          let type = undefined;
          if (hashtagOccurency.length > 1) {
            type = giveMeTheType(itemUnmarshalled.sk);
          }
          delete itemUnmarshalled.sk;
          if (type) {
            item.type = type;
          }
          return item;
        }
      ) as [];

      return { itemsUnmarshall, lastEvaluatedKey: res.LastEvaluatedKey };
    },
    [client]
  );
  type LoadMoreResult = {
    items: T[];
    lastEvaluatedKey: Record<string, AttributeValue> | undefined;
  };
  const loadMoreInt = useCallback(
    async (nextToken: typeof lastEvaluatedKey): Promise<LoadMoreResult> => {
      const localCommand = {
        ...command,
      };
      try {
        if (nextToken) {
          localCommand.ExclusiveStartKey = nextToken;
        }
        const { itemsUnmarshall, lastEvaluatedKey } = await executeCommand(
          localCommand
        );
        return { items: itemsUnmarshall, lastEvaluatedKey };
      } catch (error) {
        console.log(error);
        throw new Error("Errore QueryCommand");
      }
    },
    [command, executeCommand]
  );

  const loadMore = useCallback(async () => {
    let ignore = false;
    setIsLoadingMore(true);
    const { lastEvaluatedKey: newLastEvaluatedKey, items } = await loadMoreInt(
      lastEvaluatedKey
    );
    if (!ignore) {
      setItems((oldItems) => [...(oldItems || []), ...items]);
      setLastEvaluatedKey(newLastEvaluatedKey);
      setIsLoadingMore(false);
    }
    return () => {
      ignore = true;
    };
  }, [lastEvaluatedKey, loadMoreInt]);

  useEffect(() => {
    let ignore = false;
    setIsLoading(true);
    const load = async () => {
      let nextToken = undefined;
      const allItems: T[] = [];
      do {
        const { lastEvaluatedKey: localLastEvaluatedKey, items } =
          (await loadMoreInt(nextToken)) as LoadMoreResult;
        if (ignore) {
          break;
        }
        allItems.push(...items);
        nextToken = localLastEvaluatedKey;
      } while (autoLoadAll && nextToken);
      if (!ignore) {
        setItems(allItems);
        setLastEvaluatedKey(nextToken);
      }
      setIsLoading(false);
    };
    load();
    return () => {
      ignore = true;
    };
  }, [autoLoadAll, command, loadMoreInt]);
  const refetch = useCallback(() => {
    setLastEvaluatedKey(undefined);
    const load = async () => {
      let nextToken: Record<string, AttributeValue> | undefined = undefined;
      const allItems: T[] = [];
      do {
        const { lastEvaluatedKey: localLastEvaluatedKey, items } =
          (await loadMoreInt(nextToken)) as LoadMoreResult;
        allItems.push(...items);
        nextToken = localLastEvaluatedKey;
      } while (autoLoadAll && nextToken);
      setItems(allItems);
      setLastEvaluatedKey(nextToken);
      setIsLoading(false);
    };
    load();
  }, [autoLoadAll, loadMoreInt]);
  return {
    items,
    isLoading,
    isLoadingMore,
    loadMore,
    refetch,
  };
}

export function useDynamoGetItem<T>(
  command: GetItemCommandInput,
  autoFetch: boolean
): {
  item: T | undefined;
  error: any;
  isLoading: boolean;
  isStale: boolean;
  refetch: () => void;
} {
  const credentialProvider = useContext(AwsContext);
  const [item, setItem] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(autoFetch);
  /*  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(true);
   const [lastEvaluatedKey, setLastEvaluatedKey] = useState<
     Record<string, AttributeValue> | undefined
   >(undefined); */
  const [lastLoadCommand, setLastLoadCommand] = useState<string>(
    JSON.stringify(command)
  );
  const load = useCallback(async () => {
    try {
      if (!credentialProvider) {
        return;
      }
      setIsLoading(true);
      const client = new DynamoDBClient({
        region,
        credentials: credentialProvider,
      });
      const res = await client?.send(
        new GetItemCommand({
          ...command,
        })
      );

      if (res?.Item) {
        const el = res?.Item;
        const itemUnmarshalled = unmarshall(el);
        const hashtagOccurency = itemUnmarshalled.sk.match(/#/g);

        const uid = giveMeTheUID(itemUnmarshalled.pk);
        let item = {
          uid,
          ...itemUnmarshalled.details,
        };
        for (const [key, value] of Object.entries(itemUnmarshalled)) {
          /* specifications[key] = value */
          if (key !== "pk" && key !== "sk" && key !== "details") {
            item[key] = value;
          }
        }
        let type = undefined;
        if (hashtagOccurency.length > 1) {
          type = giveMeTheType(itemUnmarshalled.sk);
        }
        delete itemUnmarshalled.sk;
        if (type) {
          item.type = type;
        }

        if (itemUnmarshalled) {
          setItem(item);
        } else {
          setItem(undefined);
        }
      } else {
        setItem(undefined);
      }

      setLastLoadCommand(JSON.stringify(command));
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
      console.log(error);
      throw new Error("Errore QueryCommand");
    }
  }, [command, credentialProvider]);
  useEffect(() => {
    if (autoFetch) {
      setIsLoading(true);
      load();
    }
  }, [load, autoFetch]);

  const currentCommand = JSON.stringify(command);
  const isStale = useMemo(() => {
    return currentCommand !== lastLoadCommand;
  }, [currentCommand, lastLoadCommand]);

  return {
    item,
    error,
    isLoading,
    isStale,
    refetch: load,
  };
}
export function useDynamoMutation(
  defaultCommandInput: Partial<PutItemCommandInput>
) {
  const credentialProvider = useContext(AwsContext);
  const [result, setResult] = useState<PutItemCommandOutput | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const mutate = useCallback(
    async (
      command: Partial<PutItemCommandInput>
    ): Promise<PutItemCommandOutput> => {
      const fullCommand = {
        ...defaultCommandInput,
        ...command,
      } as PutItemCommandInput;
      try {
        if (!credentialProvider) {
          throw new Error("Credential Provider not found");
        }
        setIsLoading(true);
        const client = new DynamoDBClient({
          region,
          credentials: credentialProvider,
        });

        const res = await client.send(new PutItemCommand(fullCommand));
        setIsLoading(false);
        setResult(res);
        return res;

        /* Recuperare il nuovo elemento e ritornalo */
      } catch (error) {
        setIsLoading(false);
        setResult(undefined);
        console.log(error);
        setError(error);
        throw new Error("Errore PutItemCommand");
      }
    },
    [credentialProvider, defaultCommandInput]
  );

  return {
    result,
    isLoading,
    error,
    mutate,
  };
}
export function useDynamoDelete(
  defaultCommandInput: Partial<DeleteItemCommandInput>
) {
  const credentialProvider = useContext(AwsContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(undefined);
  const deletion = useCallback(
    async (command: Partial<DeleteItemCommandInput>): Promise<unknown> => {
      const fullCommand = {
        ...defaultCommandInput,
        ...command,
      } as DeleteItemCommandInput;
      try {
        if (!credentialProvider) {
          throw new Error("Credential Provider not found");
        }
        const client = new DynamoDBClient({
          region,
          credentials: credentialProvider,
        });

        const res = await client.send(new DeleteItemCommand(fullCommand));
        setIsLoading(false);
        if (res.$metadata.httpStatusCode !== 200) {
          console.log(res);
          setError(res);
          throw new Error("Errore DeleteItemCommand");
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
        setError(error);
        throw new Error("Errore DeleteItemCommand");
      }
      return;
    },
    [credentialProvider, defaultCommandInput]
  );

  return {
    isLoading,
    error,
    deletion,
  };
}

// export function useDynamoGetItem<T>(command: GetItemCommandInput) {
//   const credentialProvider = useContext(AwsContext);
//   const [item, setItem] = useState<T>();
//   const [isLoading, setIsLoading] = useState<boolean>(true);
//   useEffect(() => {
//     setIsLoading(true);
//     (async () => {
//       const client = new DynamoDBClient({
//         region,
//         credentials: credentialProvider
//       })
//       const res = await client?.send(
//         new GetItemCommand({
//           ...command,
//         })
//       );
//       const itemUnmarshall = !!res?.Item
//         ? (unmarshall(res?.Item) as T)
//         : undefined;
//       setItem(itemUnmarshall);
//       setIsLoading(false);
//     })();
//   }, [command]);

//   return {
//     item,
//     isLoading,
//   };
// }
export function giveMeTheUID(pk: string) {
  const regex = /[a-zA-Z]+#(?<entity>[a-zA-Z0-9]+)/gm;

  // Alternative syntax using RegExp constructor
  // const regex = new RegExp('[a-zA-Z]+#(?<entity>[a-zA-Z0-9]+)', 'gm')

  let m;
  let uid = undefined;
  while ((m = regex.exec(pk)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    uid = m[1];
    if (!uid) {
      throw new Error("uid not fount");
    }
    // The result can be accessed through the `m`-variable.
    /* m.forEach((match, groupIndex) => {
      console.log(`Found match, group ${groupIndex}: ${match}`);
    }); */
  }
  return uid;
}
export function giveMeTheType(sk: string) {
  const regex = /[a-zA-Z]+#[a-zA-Z]+#(?<entity>[a-zA-Z0-9]+)/gm;

  // Alternative syntax using RegExp constructor
  // const regex = new RegExp('[a-zA-Z]+#(?<entity>[a-zA-Z0-9]+)', 'gm')

  let m;
  let tyoe = undefined;
  while ((m = regex.exec(sk)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }
    tyoe = m[1];
    if (!tyoe) {
      throw new Error("uid not fount");
    }
    // The result can be accessed through the `m`-variable.
    /* m.forEach((match, groupIndex) => {
      console.log(`Found match, group ${groupIndex}: ${match}`);
    }); */
  }
  return tyoe;
}
