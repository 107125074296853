import { Button, TextField } from "@mui/material";
import { formatISO } from "date-fns";
import { ChangeEvent, useContext } from "react";
import { uploadFileToS3 } from "../../../services/S3/S3";
import { AwsContext } from "../../../services/providers/AWSProvider";
import { Country, CountryKey } from "../../../utils/models/Common.model";
import { ManualTranslation } from "../../../utils/models/Manual.model";

export function FormManual({
  lang,
  translation: translationProp,
  onChange,
}: {
  lang: CountryKey;
  translation?: ManualTranslation;
  onChange: (key: CountryKey, translation: ManualTranslation) => void;
}) {
  const credentialProvider = useContext(AwsContext);
  const translation: ManualTranslation = translationProp || {
    title: "",
    subtitle: "",
    manual_path: "",
    country: Country[lang],
    created_at: formatISO(new Date()),
  };

  const handleChangeFile =
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        uploadFileToS3(file, file.name, credentialProvider!, 'download')
          .then((res) => {
            onChange(lang, {
              ...translation,
              manual_path: res,
            }
            );
          })
          .catch(() => {
            alert("Errore Caricamento File");
          });
      }
    };

  const handleChangeField =
    (field: keyof ManualTranslation) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(lang, {
        ...translation,
        [field]: event.target.value,
      });
    };

  return (
    <div className="mt-4">
      <div className="grid grid-rows-2 grid-cols-1 gap-4">
        <div>
          <p className="font-semibold">Title</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              size="small"
              type="text"
              name="title"
              value={translation.title}
              onChange={handleChangeField("title")}
            />
          </div>
        </div>
        <div>
          <p className="font-semibold">Subtitle</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Subtitle"
              variant="outlined"
              size="small"
              type="text"
              name="subtitle"
              value={translation.subtitle}
              onChange={handleChangeField("subtitle")}
            />
          </div>
        </div>
      </div>
      <div className="mt-4">
        <p className="font-semibold mb-1">File</p>
        <div>
          <div className="mt-1">
            <div>
              <Button
                className="buttons-style"
                variant="contained"
                component="label"
              >
                Upload File
                <input
                  type="file"
                  accept="image/*,.pdf"
                  style={{ display: "none" }}
                  onChange={handleChangeFile}
                />
              </Button>
              <div className="mt-1">
                {translation.manual_path &&
                <a href={translation.manual_path} target="_blank" rel="noreferrer">{translation.manual_path}</a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
