import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const LoaderContext = createContext<{
  isLoading: boolean;
  setLoader: (key: string, value: boolean) => void;
  loaders: Record<string, boolean>;
}>({
  isLoading: false,
  setLoader: (key: string, value: boolean) => {},
  loaders: {},
});

export function LoaderProvider({ children }: PropsWithChildren<{}>) {
  const [loaders, setLoaders] = useState<Record<string, boolean>>({});
  const setLoader = useCallback((key: string, value: boolean) => {
    setLoaders((loaders) => {
      return {
        ...loaders,
        [key]: value,
      };
    });
  }, []);

  const value = useMemo(() => {
    const values = Object.values(loaders);
    const isLoading = values.some((value) => value);
    return {
      isLoading,
      setLoader,
      loaders,
    };
  }, [setLoader, loaders]);
  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
}

export function useLoader(key: string) {
  const { isLoading, setLoader } = useContext(LoaderContext);
  const setLoading = useCallback(
    (value: boolean) => setLoader(key, value),
    [key, setLoader]
  );
  return {
    setLoading,
    isLoading,
  };
}
