import { TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { CountryKey, TranslationElement } from "../../../utils/models/Common.model";

export function FormTranslation({
  lang,
  translation: translationProp,
  onChange,
}: {
  lang: CountryKey;
  translation?: TranslationElement;
  onChange: (key: CountryKey, translation: TranslationElement) => void;
}) {
  const translation: TranslationElement = translationProp || {
    description: '',
    translation: ''
  };
  const handleChangeField =
    (field: keyof TranslationElement) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange(lang, {
        ...translation,
        [field]: event.target.value,
      });
    };

  return (
    <div className="mt-4">
      <div className="grid grid-rows-2 grid-cols-1 gap-4">
        <div>
          <p className="font-semibold">Translation</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Translation"
              variant="outlined"
              size="small"
              type="text"
              name="translation"
              value={translation.translation}
              onChange={handleChangeField("translation")}
            />
          </div>
        </div>
        <div>
          <p className="font-semibold">Description</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Description"
              variant="outlined"
              size="small"
              type="text"
              name="description"
              value={translation.description}
              onChange={handleChangeField("description")}
            />
          </div>
        </div>

      </div>

    </div>
  );
}
