import {
  Route,
  Routes
} from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import FAQ from "../pages/FAQ/FAQ";
import SideBar from "./SideBar";
import Devices from "../pages/Devices/Devices";
import Categories from "../pages/Categories/Categories";
import ListDownloads from "../pages/Manuals/Manuals";
import NewFAQ from "../pages/FAQ/NewFAQ";
import SpecIssues from "../pages/SpecIssues/SpecIssues";
import NewCategorie from "../pages/Categories/AddCategory";
import NewDevice from "../pages/Devices/NewDevice";
import NewDownload from "../pages/Manuals/NewDownload";
import NewSpecIssues from "../pages/SpecIssues/NewSpecIssues";
import StoreBrand from "../pages/StoreBrand/StoreBrand";
import NewStoreBrand from "../pages/StoreBrand/NewStoreBrand";
import Translations from "../pages/Translations/Translations";
import Translation from "../pages/Translations/Translation";
import NewRetailerManagement from "../pages/RetailerManagement/NewRetailerManagement";
import RetailerManagement from "../pages/RetailerManagement/RetailerManagement";
import CustomizeTicketForm from "../pages/CustomizeTicketForm/CustomizeTicketForm";
import SingleRequest from "../pages/Progress/SingleProgress";
import Progress from "../pages/Progress/Progress";
import SelectContactUs from "../pages/ContactUsManagement/SelectContactUs";
import ContactUsManagementCustomer from "../pages/ContactUsManagement/ContactUsManagementCustomer";
import ContactUsManagementRetailer from "../pages/ContactUsManagement/ContactUsManagementRetailer";
export function Layout() {
  return (
    <>
      <Routes>
        <Route element={<SideBar />}>
          <Route Component={Dashboard} path="/"></Route>
          <Route Component={Devices} path="/devices"></Route>
          <Route Component={NewDevice} path="/devices/:uidDevice"></Route>
          <Route Component={Categories} path="/categories"></Route>
          <Route Component={NewCategorie} path="/categories/:uidCategorie"></Route>
          <Route Component={FAQ} path="/faq"></Route>
          <Route Component={NewFAQ} path="/faq/:uidFAQ"></Route>
          <Route Component={ListDownloads} path="/manuals"></Route>
          <Route Component={NewDownload} path="/manuals/:uidManual"></Route>
          <Route Component={SpecIssues} path="/specifications"></Route>
          <Route Component={NewSpecIssues} path="/specifications/:uidSpec"></Route>
          <Route Component={StoreBrand} path="/store-brand"></Route>
          <Route Component={NewStoreBrand} path="/store-brand/:uidStoreBrand"></Route>
          <Route Component={CustomizeTicketForm} path="/ticket-form"></Route>
          <Route Component={SelectContactUs} path="/contact-us"></Route>
          <Route Component={ContactUsManagementCustomer} path="/contact-us/customer"></Route>
          <Route Component={ContactUsManagementRetailer} path="/contact-us/retailer"></Route>
          <Route Component={RetailerManagement} path="/retailer-management"></Route>
          <Route Component={NewRetailerManagement} path="/retailer-management/:uidRetailer"></Route>
          <Route Component={Progress} path="/progress"></Route>
          <Route Component={SingleRequest} path="/progress/:uidProgress"></Route>
          <Route Component={Translations} path="/translations"></Route>
          <Route Component={Translation} path="/translations/:uidTranslation"></Route>
        </Route>
      </Routes>
    </>
  );
}
