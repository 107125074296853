import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { CognitoIdentityCredentialProvider } from "@aws-sdk/credential-providers";
const Bucket = process.env.REACT_APP_BUCKET
const region = process.env.REACT_APP_REGION
const cloudFrontDomain = process.env.REACT_APP_CLOUDFRONT_ASSETS_DOMAIN

// 👇 Uploading the file using the fetch API to the server
export async function uploadFileToS3(blob: File | Blob, filename: string, credentials: CognitoIdentityCredentialProvider, prefix: string): Promise<string> {
    const s3Client = new S3Client({ region, credentials });
    const command = new PutObjectCommand({
        Bucket,
        Key: prefix+'/'+filename.replaceAll(' ', '-'),
        Body: blob,
    });
    try {
        await s3Client.send(command)
        return cloudFrontDomain + prefix+'/'+filename.replaceAll(' ', '-');
    } catch (error) {
        console.log(error)
        throw error;
    }
}