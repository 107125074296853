import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Checkbox, TablePagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { ChangeEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Retailer } from "../utils/models/Retailer.model";
import { useRetailerDeletion } from "../services/Dynamo/delete";
import DeletionConfirm from "./DeletionConfirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function TableRetailerManagement({
  retailers,
  isLoading,
  onDelete,
  selectedUids,
  setSelectedUids,
}: {
  retailers: Retailer[],
  isLoading: boolean,
  onDelete: (row: Retailer) => void,
  selectedUids: Retailer['uid'][],
  setSelectedUids: (uid: Retailer['uid'][]) => void
}) {
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem
  } = useRetailerDeletion();
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentRetailer, setCurrentRetailer] = useState<Retailer | undefined>(undefined)
  const deleteRetailer = useCallback(() => {
    if (currentRetailer) {
      deleteItem(currentRetailer.uid).then((res) => {
        onDelete(currentRetailer)
      });
      setDeleteModal(false)
      setCurrentRetailer(undefined)
    }
  }, [currentRetailer, deleteItem, onDelete])
  const handleClose = useCallback(() => {
    setDeleteModal(false)
    setCurrentRetailer(undefined)
  }, [])
  const handleCheck = (e: any, uid: Retailer['uid'])=>{
    if(e.target.checked){
      setSelectedUids([...(selectedUids || []),uid ])
    }else{
      setSelectedUids((selectedUids || []).filter((el)=>el!== uid))
    }
  }
  return (
    <>
      {deleteModal && currentRetailer && (

        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this retailer with name ${currentRetailer.store_name} and VAT ${currentRetailer.vat}'?`}
          deletionMethod={deleteRetailer} />
      )}
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table
          
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">#</StyledTableCell>
              <StyledTableCell>Store Name</StyledTableCell>
              <StyledTableCell align="center">City</StyledTableCell>
              <StyledTableCell align="center">Country</StyledTableCell>
              <StyledTableCell align="center">Status</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <StyledTableCell align="center" colSpan={7}>
                  Fetching data...
                </StyledTableCell>
              </StyledTableRow>
            )}
            {retailers.length === 0 && !isLoading && (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <StyledTableCell align="center" colSpan={7}>
                  No Retailers Availables.
                </StyledTableCell>
              </StyledTableRow>
            )}
            {retailers
              .filter((el, i) => (i >= page * rowsPerPage) && (i < (page + 1) * rowsPerPage))
              .map((row) => (
                <>
                  <StyledTableRow key={row.uid}>
                    <StyledTableCell align="left">
                      <div className="flex items-center">
                        <Checkbox onChange={(e)=>{handleCheck(e, row.uid)}} checked={selectedUids?.find((uid)=>uid === row.uid)!== undefined} />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <p>{row.store_name}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{row.city}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{row.country}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{row.status}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <p>{row.email}</p>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Button
                        variant="text"
                        onClick={() => navigate(`/retailer-management/${row.uid}`)}
                        className="buttons-edit-delete-style mr-4"
                      >
                        <EditIcon />
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setCurrentRetailer(row)
                          setDeleteModal(true)
                        }
                        }
                        className="buttons-edit-delete-style"
                      >
                        <DeleteIcon />
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>

                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200, 500]}
        component="div"
        count={retailers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
