import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Snackbar,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useCustomerContactUsMutation } from "../../../services/Dynamo/mutation";
import { Country, CountryKey } from "../../../utils/models/Common.model";
import { CustomerContactUs } from "../../../utils/models/ContactUs.model";
import { useCustomerContactUsList } from "../../../services/Dynamo/query";
import { useLoader } from "../../../services/providers/LoaderProvider";
import { useNavigate } from "react-router-dom";
import { langs } from "../../../utils/interface";
import { TextEditor } from "../../../components/TextEditor";

export function TabsLangContactUsCustomer() {
  const navigate = useNavigate();
  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);
  const { isLoading, items, refetch } = useCustomerContactUsList();
  const { setLoading } = useLoader("ContactUs");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const {
    isLoading: isLoadingMutCustomer,
    error: errorMutCustomer,
    mutateItem: mutateItemCustomer,
  } = useCustomerContactUsMutation();

  const onSave = useCallback(
    (element: CustomerContactUs) => {
      mutateItemCustomer(element).then(() => {
        setShowNotificationSuccess(true);
        setTimeout(() => {
          setShowNotificationSuccess(false);
          refetch();
        }, 2000);
      });
    },
    [mutateItemCustomer, refetch]
  );

  const [value, setValue] = useState<undefined | CountryKey>(undefined);
  useEffect(() => {
    if (value === undefined) {
      setValue(langs[0]);
    }
  }, [value]);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value || false}
            onChange={(_, value) => setValue(value)}
            aria-label="basic tabs example"
          >
            {langs.map((key) => (
              <Tab key={key} label={Country[key]} value={key} />
            ))}
          </Tabs>
        </Box>
        {langs.map((key) => (
          <SingleElement
            lang={key}
            selectedLang={value}
            contactUs={items?.find((contact) => contact.country === key)}
            onSave={onSave}
          ></SingleElement>
        ))}
      </Box>
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

function SingleElement({
  lang,
  selectedLang,
  contactUs,
  onSave,
}: {
  lang: string;
  selectedLang?: string;
  contactUs?: CustomerContactUs;
  onSave: (contactUs: CustomerContactUs) => void;
}) {
  // campi
  const [phone, setPhone] = useState<string>("");
  const [phoneMessage, setPhoneMessage] = useState<string>("");
  const [phoneVisible, setPhoneVisible] = useState<boolean>(false);
  const [mail, setMail] = useState<string>("");
  const [mailVisible, setMailVisible] = useState<boolean>(false);
  const [fax, setFax] = useState<string>("");
  const [faxVisible, setFaxVisible] = useState<boolean>(false);
  const [header, setHeader] = useState<string>("");
  const [footerMessage, setFooterMessage] = useState<string>("");

  useEffect(() => {
    setPhone(contactUs?.phone || "");
    setPhoneMessage(contactUs?.phoneMessage || "");
    setPhoneVisible(contactUs?.phoneVisible || false);
    setMail(contactUs?.mail || "");
    setMailVisible(contactUs?.mailVisible || false);
    setFax(contactUs?.fax || "");
    setFaxVisible(contactUs?.faxVisible || false);
    setHeader(contactUs?.header || "");
    setFooterMessage(contactUs?.footerMessage || "");
  }, [contactUs]);

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  const handleSaveCustomer = () => {
    const oldContactUs = contactUs || {};
    onSave({
      ...oldContactUs,
      phone,
      phoneMessage,
      phoneVisible,
      mail,
      mailVisible,
      fax,
      faxVisible,
      header,
      footerMessage,
      type: "customer"
    } as CustomerContactUs);
  };

  return (
    <>
      <div role="tabpanel" hidden={selectedLang !== lang}>
        <div className="container mx-auto">
          <div className="grid grid-cols-7 grid-rows-1 gap-2 mt-4">
            <div className="col-span-3">
              <p className="font-semibold">Phone</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Phone"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChangeString(setPhone)}
                />
              </div>
            </div>
            <div className="col-span-3">
              <p className="font-semibold">Phone Message</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Phone Message"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="phoneMessage"
                  value={phoneMessage}
                  onChange={handleChangeString(setPhoneMessage)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Phone Visible</p>
              <div className="mt-1">
                <Checkbox
                  checked={phoneVisible}
                  onChange={(event) => setPhoneVisible(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-7 grid-rows-1 gap-2 mt-4">
            <div className="col-span-6">
              <p className="font-semibold">Mail</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Mail"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="mail"
                  value={mail}
                  onChange={handleChangeString(setMail)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Mail Visible</p>
              <div className="mt-1">
                <Checkbox
                  checked={mailVisible}
                  onChange={(event) => setMailVisible(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-7 grid-rows-1 gap-2 mt-4">
            <div className="col-span-6">
              <p className="font-semibold">Fax</p>
              <div className="mt-1">
                <TextField
                  fullWidth
                  label="Fax"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fax"
                  value={fax}
                  onChange={handleChangeString(setFax)}
                />
              </div>
            </div>
            <div className="col-span-1">
              <p className="font-semibold">Fax Visible</p>
              <div className="mt-1">
                <Checkbox
                  checked={faxVisible}
                  onChange={(event) => setFaxVisible(event.target.checked)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 grid-rows-1 gap-6 mt-4">
            <div>
              <p className="font-semibold mb-1">Header</p>
              <TextEditor body={header} prefix="contact_us/consumer/header" setBody={setHeader} />
            </div>
            <div>
              <p className="font-semibold mb-1">Footer</p>
              <TextEditor body={footerMessage} prefix="contact_us/consumer/footer" setBody={setFooterMessage} />
            </div>
          </div>
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            variant="contained"
            onClick={handleSaveCustomer}
            className="buttons-style"
          >
            <SaveIcon className="icon-button" />
            <span className="w-full">Save Language {selectedLang}</span>
          </Button>
        </div>
      </div>
    </>
  );
}
