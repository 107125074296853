import { useAuth } from "oidc-react";
import logo from "./Clementoni logo - colori - positivo.png";
import { Button, Menu, MenuItem, Avatar, Tooltip, IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useState, MouseEvent } from "react";

export default function Header() {
  const { userData, signOut } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="bg-[#FFFFFF] min-h-full px-4">
      <div className="p-4 flex justify-between items-center">
        <a href="/devices">
          <img src={logo} alt="Clementoni" className="logo-style"></img>
        </a>
        <div>
          <Tooltip title={userData?.profile.email}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ width: 32, height: 32, backgroundColor: "#1D4EBB" }}
              >
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>
              <Button
                variant="contained"
                onClick={() => signOut()}
                className="buttons-style"
              >
                <LogoutIcon className="icon-button" />
                <span className="w-full">Logout</span>
              </Button>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
