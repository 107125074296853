import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { format, parseISO } from "date-fns";
import { ChangeEvent, Fragment, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFaqDeletion } from "../services/Dynamo/delete";
import { DATE_DISPLAY } from "../utils/interface";
import { Faq } from "../utils/models/Faq.model";
import DeletionConfirm from "./DeletionConfirm";
import PublishIcon from "@mui/icons-material/Publish";
import { useFaqMutation } from "../services/Dynamo/mutation";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";
import { TablePagination } from "@mui/material";
import { CountryKey } from "../utils/models/Common.model";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Row(props: { row: Faq; onDelete: (row: Faq) => void }) {
  const navigate = useNavigate();
  const { row, onDelete } = props;
  let country: CountryKey;
  if ("it" in row.translations) {
    country = "it";
  } else {
    const lang = Object.keys(row.translations)[0] as CountryKey;
    country = lang;
  }
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const handleClose = useCallback(() => {
    setDeleteModal(false);
  }, []);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useFaqMutation();
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem,
  } = useFaqDeletion();
  const deleteFAQ = useCallback(() => {
    deleteItem(row.uid).then((res) => {
      onDelete(row);
    });
    setDeleteModal(false);
  }, [deleteItem, onDelete, row]);
  const onPublish = onDelete;
  const handlePublish = useCallback(async () => {
    await mutateItem({
      ...row,
      published: true,
    } as Faq);
    onPublish(row);
  }, [mutateItem, onPublish, row]);

  return (
    <>
      {deleteModal && (
        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this faq '${row.name}'?`}
          deletionMethod={deleteFAQ}
        />
      )}
      <Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell>
          <StyledTableCell align="center">{row.uid}</StyledTableCell>
          <StyledTableCell>{country}</StyledTableCell>
          <StyledTableCell>{row.name}</StyledTableCell>
          {row.created_at && (
            <StyledTableCell align="center" sx={{ minWidth: "130px" }}>
              {row?.created_at !== undefined
                ? `${format(parseISO(row.created_at), DATE_DISPLAY)}`
                : ""}
            </StyledTableCell>
          )}
          <StyledTableCell align="center" sx={{ minWidth: "176px" }}>
            <div className="">
              <Button
                variant="text"
                onClick={() => navigate(`/faq/${row.uid}`)}
                className="buttons-edit-delete-style mr-4"
              >
                <EditIcon />
              </Button>
              <Button
                variant="text"
                onClick={() => setDeleteModal(true)}
                className="buttons-edit-delete-style mr-4"
              >
                <DeleteIcon />
              </Button>
              {row.published === false && (
                <Tooltip title="Publish">
                  <Button
                    variant="text"
                    onClick={() => handlePublish()}
                    className="buttons-edit-delete-style mr-4"
                  >
                    <PublishIcon />
                  </Button>
                </Tooltip>
              )}
            </div>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <List className="p-0">
                  {Object.keys(row.translations).map((trans, i) => (
                    <Fragment key={row.translations[trans]?.country}>
                      {i > 0 && <Divider />}
                      <ListItem>
                        <ListItemText
                          primary={row.translations[trans]?.country}
                        />
                        <ListItemText
                          sx={{ maxWidth: "800px" }}
                          primary={row.translations[trans]?.title}
                        />
                        <ListItemText
                          sx={{ textAlign: "right" }}
                          primary={
                            row.translations[trans]?.created_at !== undefined
                              ? format(
                                  parseISO(row.translations[trans]!.created_at),
                                  DATE_DISPLAY
                                )
                              : ""
                          }
                        />
                      </ListItem>
                    </Fragment>
                  ))}
                </List>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </Fragment>
    </>
  );
}

export default function TableFAQ({
  faqs,
  isLoading,
  onDelete,
}: {
  faqs: Faq[];
  isLoading: boolean;
  onDelete: (row: Faq) => void;
}) {
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [page, setPage] = useState(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <>
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableCell align="center">#</StyledTableCell>
              <StyledTableCell align="center">Lang</StyledTableCell>
              <StyledTableCell align="center">Faq</StyledTableCell>
              <StyledTableCell align="center">Date</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <StyledTableCell align="center" colSpan={6}>
                  Fetching data...
                </StyledTableCell>
              </StyledTableRow>
            )}
            {faqs.length === 0 && !isLoading && (
              <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                <StyledTableCell align="center" colSpan={6}>
                  No Faqs Availables.
                </StyledTableCell>
              </StyledTableRow>
            )}
            {faqs
              .sort(
                (a, b) =>
                  parseISO(b.created_at).getTime() -
                  parseISO(a.created_at).getTime()
              )
              .filter(
                (el, i) =>
                  i >= page * rowsPerPage && i < (page + 1) * rowsPerPage
              )
              .map((row) => (
                <Row key={row.uid} row={row} onDelete={onDelete} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200, 500]}
        component="div"
        count={faqs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
