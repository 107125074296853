export const editorFaqPlugins = [
    'advlist autolink lists link image charmap print preview anchor image',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount'
]
export const editorFaqToolbar = 'undo redo | formatselect | ' +
    'bold italic backcolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent link | ' +
    'removeformat | help image '
export const editorFaqContentStyle = 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
// export const editorFaqFilePickerType = "file image media"
export const editorFaqFilePickerType = "image"
export const editorFaqImagesFileTypes = 'jpeg,jpg,jpe,jfi,jif,jfif,png,bmp,webp'