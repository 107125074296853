import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useCategoryList } from "../services/Dynamo/query";
import { useLoader } from "../services/providers/LoaderProvider";
import { Category } from "../utils/models/Category.model";
import { Box, Chip } from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

export function CategoryPicker({
  type,
  selected,
  onChange,
}: {
  type?: "faq" | "download";
  selected: Category["uid"][];
  onChange: Dispatch<SetStateAction<string[]>>;
}) {
  const { isLoading, items } = useCategoryList();
  const { setLoading } = useLoader("Category");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const domainItems = useMemo(
    () => (!type ? items : items?.filter((one) => one.type.includes(type))),
    [items, type]
  );
  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    onChange(Array.isArray(value) ? value : [value]);
  };
  let selectedCategories: any[] = [];
  if (domainItems) {
    selectedCategories = domainItems.filter((one) => selected.includes(one.uid));
  }
  return (
    <div>
      {!isLoading && domainItems && (
        <>
          <p className="font-semibold">Select Categories</p>
          <FormControl sx={{ my: 1, width: "100%" }} size="small">
            <InputLabel id="multiple-select-categories">
              Select Categories
            </InputLabel>
            <Select
              error={selected.length === 0}
              multiple
              value={selected}
              onChange={handleChange}
              input={<OutlinedInput label="Select Categories" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selectedCategories.map((one) => (
                    <Chip key={one.uid} label={one.title} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {domainItems.map((categoria) => (
                <MenuItem value={categoria.uid} key={categoria.uid}>
                  <ListItemText primary={categoria.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </div>
  );
}
