import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Button, CircularProgress } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Category } from "../utils/models/Category.model";
import { useCallback, useState } from "react";
import { useCategoryDeletion } from "../services/Dynamo/delete";
import DeletionConfirm from "./DeletionConfirm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1D4EBB",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TableCategories({ categories, onDelete }: { categories: Category[], onDelete: (row: Category) => void }) {
  const navigate = useNavigate();

  return (
    <>
      
      <TableContainer component={Paper} sx={{ borderRadius: 0 }}>
        <Table
          
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">#</StyledTableCell>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell align="center">Order</StyledTableCell>
              <StyledTableCell align="center">Used by</StyledTableCell>
              <StyledTableCell align="center">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.sort((a, b) => a.order - b.order).map((row, index) => (
              <StyledTableRow key={row.uid}>
                <StyledTableCell align="left">{index+1}</StyledTableCell>
                <StyledTableCell component="th" scope="row"><p>{row.title}</p></StyledTableCell>
                <StyledTableCell align="center">{row.order}</StyledTableCell>
                <StyledTableCell align="center">{row.type.join(', ')}</StyledTableCell>
                <StyledTableCell align="center">
                  <Button
                    variant="text"
                    onClick={() => navigate(`/categories/${row.uid}`)}
                    className="buttons-edit-delete-style mr-4"
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      onDelete(row)
                    }}
                    className="buttons-edit-delete-style"
                  >
                    <DeleteIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
    </>
  );
}
