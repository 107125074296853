import { CircularProgress } from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import { useCallback, useContext, useState } from "react";
import { uploadFileToS3 } from "../services/S3/S3";
import { AwsContext } from "../services/providers/AWSProvider";
import {
  editorFaqToolbar
} from "../utils/tinymce/config";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
/* import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'; */

export function TextEditor({
  body,
  prefix,
  setBody,
}: {
  body: string;
  prefix: string;
  setBody: (body: string) => void;
}) {
  const [counter, setCounter] = useState(0);
  const credentialProvider = useContext(AwsContext);
  // const [initialValue] = useState(body);
  // const editorRef = useRef<TinyMCEEditor | null>(null);

  const uploadFile = useCallback(
    (blobInfo: any, progress: any): Promise<string> => {
      return new Promise((resolve, reject) => {
        const blob = blobInfo.blob();
        const filename = blobInfo.filename();
        const name = blobInfo.name();
        if (credentialProvider) {
          uploadFileToS3(blob, filename, credentialProvider, prefix)
            .then((filepath) => {
              if (filepath) {
                resolve(filepath);
              }
            })
            .catch(() => {
              reject();
            });
        } else {
          alert("no credentials provided");
        }
      });
    },
    [credentialProvider]
  );

  if (typeof setBody !== 'function' || typeof body !== 'string') {
    return <></>;
  }
  try {
    return (
      // onInit={(evt, editor) => (editorRef.current = editor)}
      // initialValue={initialValue}
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        onEditorChange={setBody}
        plugins={["image"]}
        toolbar={editorFaqToolbar}
        value={body}
        init={{
          height: 500,
          menubar: false,
          plugins: ["image", "link", "lists"],
          toolbar: editorFaqToolbar,
          // content_style: editorFaqContentStyle,
          file_picker_types: "image",
          images_upload_handler: uploadFile,
          // onchange: change,
        }}
      />
    );
  } catch (e) {
    console.error("Errore tinymce " + counter);
    setTimeout(() => setCounter(counter + 1), 200);
    return <CircularProgress />;
  }
}
