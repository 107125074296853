import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, Snackbar, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../services/providers/LoaderProvider";
import { useManualList } from "../../services/Dynamo/query";
import TableManuals from "../../components/TableManuals";
import { Manual } from "../../utils/models/Manual.model";

export default function ListDownloads() {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useManualList();
  const { setLoading } = useLoader("Manuals");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);
  const onDelete = useCallback(() => {
    refetch();
    setShowNotificationSuccess(true);
    setTimeout(() => {
      setShowNotificationSuccess(false);
    }, 2000);
  }, [refetch]);

  const [currentSearch, setCurrentSearch] = useState<string>("")
  const [filterdItems, setFilteredItems] = useState<Manual[]>([])
  useEffect(() => {
    const timeout = setTimeout(() => {
      const filteredItems = (items || [])?.filter((el) => {
        return el.name?.toLowerCase().includes(currentSearch.toLowerCase())
      })
      setFilteredItems(filteredItems)
    }, 1000);
    return () => clearTimeout(timeout)
  }, [currentSearch, items])

  return (
    <div className="container mx-auto">
      <>
        <div className="flex justify-between items-center my-5">
          <h1 className="text-3xl">List of Manuals / Downloads</h1>
          <Button
            variant="contained"
            onClick={() => navigate("/manuals/new")}
            className="buttons-style"
          >
            <AddCircleIcon className="icon-button" />
            <span className="w-full">add Manual</span>
          </Button>
        </div>
        <div className="my-3">
          <p className="font-semibold">Insert search terms</p>
          <div className="mt-1">
            <TextField
              fullWidth
              label="Insert search terms"
              variant="outlined"
              size="small"
              type="text"
              name="title"
              value={currentSearch}
              onChange={(e) => { setCurrentSearch(e.target.value) }}
            />
          </div>
        </div>
        <TableManuals
          onDelete={onDelete}
          isLoading={isLoading}
          manuals={filterdItems || []}
        />
      </>
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
