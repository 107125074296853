import { Category } from '../../utils/models/Category.model';
import { TranslationEntity } from '../../utils/models/Common.model';
import { CustomerContactUs, RetailerContactUs } from '../../utils/models/ContactUs.model';
import { Device } from '../../utils/models/Device.model';
import { Faq } from '../../utils/models/Faq.model';
import { Manual } from '../../utils/models/Manual.model';
import { Progress } from '../../utils/models/Progress.model';
import { Retailer } from '../../utils/models/Retailer.model';
import { IssueAndSpecification } from '../../utils/models/Spec.model';
import { StoreBrand } from '../../utils/models/StoreBrand.model';
import { CustomerTicket, RetailerTicket } from '../../utils/models/Ticket.model';
import { useMutationItem } from './common';
export function useDeviceMutation() {
    return useMutationItem<Device>('device')
}
export function useFaqMutation() {
    return useMutationItem<Faq>('faq')
}
export function useManualMutation() {
    return useMutationItem<Manual>('download')
}
export function useCategoryMutation() {
    return useMutationItem<Category>('category')
}
export function useCustomerTicketFormMutation() {
    return useMutationItem<CustomerTicket>('ticketform', 'ticketform#customer')
}
export function useRetailerTicketFormMutation() {
    return useMutationItem<RetailerTicket>('ticketform', 'ticketform#retailer')
}
export function useCustomerContactUsMutation() {
    return useMutationItem<CustomerContactUs>('contactUs', 'contactUs#customer')
}
export function useRetailerContactUsMutation() {
    return useMutationItem<RetailerContactUs>('contactUs', 'contactUs#retailer')
}
export function useStoreBrandMutation() {
    return useMutationItem<StoreBrand>('storebrand')
}
export function useRetailerMutation() {
    return useMutationItem<Retailer>('retailer')
}
export function useTranslationMutation() {
    return useMutationItem<TranslationEntity>('translation')
}
export function useProgressMutation() {
    return useMutationItem<Progress>('progress')
}
export function useSpecMutation() {
    return useMutationItem<IssueAndSpecification>('issue')
}