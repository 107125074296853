import { CssBaseline, AppBar } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../pages/Header/Header";
import { GlobalLoader } from "./GlobalLoader";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
const routes = [
  {
    label: "Devices",
    path: "/devices",
  },
  {
    label: "Categories",
    path: "/categories",
  },
  {
    label: "FAQ",
    path: "/faq",
  },
  {
    label: "Manuals / Downloads",
    path: "/manuals",
  },
  {
    label: "Spec & Issues",
    path: "/specifications",
  },
  {
    label: "StoreBrand",
    path: "/store-brand",
  },
  {
    label: "Customize Ticket Form",
    path: "/ticket-form",
  },
  {
    label: "Customize Contact Us",
    path: "/contact-us",
  },
  {
    label: "Retailer Management",
    path: "/retailer-management",
  },
  {
    label: "Progress",
    path: "/progress",
  },
  {
    label: "Translations",
    path: "/translations",
  },
];
const drawerWidth = 210;

export default function SideBar() {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        className="app-bar"
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: "white" }}
      >
        <Header />
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Box sx={{ overflow: "auto"}} className="sidebar-box">
          <List>
            {routes.map((el, index) => (
              <Fragment key={el.path}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      navigate(el.path);
                    }}
                  >
                    {/* <ListItemIcon>
      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
    </ListItemIcon> */}
                    <ListItemText primary={el.label} />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="sidebar-box-content">
        <Outlet />
      </Box>
      <div className="fixed w-full bottom-0">
        <GlobalLoader />
      </div>
    </Box>
  );
}
