import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { TabsLangContactUsCustomer } from "./pieces/TabsLangContactUsCustomer";

export default function ContactUsManagementCustomer() {
  const navigate = useNavigate();

  return (
    <div className="container mx-auto">
      <Link to=".." relative="path">
        <Button
          className="buttons-style"
          variant="contained"
          onClick={() => {
            navigate("../");
          }}
        >
          <ArrowCircleLeftIcon className="icon-button" />{" "}
          <span className="w-full">Go back</span>
        </Button>
      </Link>
      <div className="my-4">
        <hr></hr>
      </div>
      <div className="">
        <h1 className="text-3xl">Contact Us management Customer</h1>
        <p className="font-bold">Fields</p>
        <p className="text-sm">
          Customize which fields to display in the contact us Customer
        </p>
        <TabsLangContactUsCustomer />
      </div>
    </div>
  );
}
