import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCategoryList, useFaqList } from "../../services/Dynamo/query";
import { useLoader } from "../../services/providers/LoaderProvider";
import TableCategories from "../../components/TableCategories";
import DeletionConfirm from "../../components/DeletionConfirm";
import { useCategoryDeletion } from "../../services/Dynamo/delete";
import { Category } from "../../utils/models/Category.model";
import { useFaqMutation } from "../../services/Dynamo/mutation";
import { Faq } from "../../utils/models/Faq.model";

export default function Categories() {
  const navigate = useNavigate();
  const { isLoading, items, refetch } = useCategoryList();
  const faqs = useFaqList().items;
  const { setLoading } = useLoader("Categories");
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);
  const [isDetachingFaqs, setIsDetachingFaqs] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentRow, setCurrentRow] = useState<Category | undefined>(undefined)
  const {
    isLoading: isLoadinDeletion,
    error: errorDeletion,
    deleteItem
  } = useCategoryDeletion();
  const {
    mutateItem
  } = useFaqMutation();
  const onDelete = useCallback((row: Category) => {
    setCurrentRow(row)

  }, []);
  useEffect(() => {
    if (currentRow) {
      setDeleteModal(true)
    }
  }, [currentRow]);
  const handleClose = useCallback(() => {
    setDeleteModal(false)
  }, []);
  const deleteCategory = useCallback(async () => {
    if (currentRow) {
      setIsDetachingFaqs(true)
      document.body.classList.add('hide-overlay-to-body');
      const relatedFaqs = faqs?.filter((el) => el.categories.includes(currentRow.uid)) || []

      await Promise.all(relatedFaqs.map(async (faq) => {
        const oldFaq = faq || {};
        await mutateItem({
          ...oldFaq,
          categories: faq.categories.filter((el) => el !== currentRow.uid),
        } as Faq)
      }));
      await deleteItem(currentRow.uid)

      setIsDetachingFaqs(false)
      document.body.classList.remove('hide-overlay-to-body');
    }
    setDeleteModal(false)
    setCurrentRow(undefined)
    setShowNotificationSuccess(true)
    refetch()
    setTimeout(() => {
      setShowNotificationSuccess(false)
    }, 2000)
  }, [currentRow, deleteItem, faqs, mutateItem, refetch])
  return (
    <>
      {deleteModal && currentRow && (

        <DeletionConfirm
          handleClose={handleClose}
          message={`Are you sure you want to delete this category titled '${currentRow.title}' and detach related FAQ?(${faqs?.filter((el) => el.categories.includes(currentRow.uid))?.length} faqs attached)`}
          deletionMethod={deleteCategory} />
      )}
      <div className="container mx-auto">
        <>
          <div className="flex justify-between items-center my-5">
            <h1 className="text-3xl">List of Categories</h1>
            <Button
              variant="contained"
              onClick={() => navigate("/categories/new")}
              className="buttons-style"
            >
              <AddCircleIcon className="icon-button" />
              <span className="w-full">add categorie</span>
            </Button>
          </div>
          <TableCategories categories={items || []} onDelete={onDelete} />
        </>
        {showNotificationSuccess && (
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={true}
            message="Operation carried out successfully"
          >
            <Alert
              sx={{ color: "white", backgroundColor: "#1D4EBB" }}
              severity="info"
            >
              Operation carried out successfully
            </Alert>
          </Snackbar>
        )}
        {isDetachingFaqs &&
          <div className="blocker-overlay">
            <div style={{ position: "absolute", top: "50%", left: "50%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <CircularProgress />
              <div className="text-white">Operation in progress</div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
