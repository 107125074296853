import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  Snackbar,
  TextField,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCategoryGet } from "../../services/Dynamo/get";
import { useCategoryMutation } from "../../services/Dynamo/mutation";
import { Category } from "../../utils/models/Category.model";
import { CountryKey } from "../../utils/models/Common.model";

export default function NewCategorie() {
  const navigate = useNavigate();
  const { uidCategorie } = useParams();
  const isModify = uidCategorie !== "new";
  const {
    isLoading,
    item: categorie,
    refetch,
    isStale,
  } = useCategoryGet(uidCategorie as string, isModify);
  const {
    isLoading: isLoadinMut,
    error: errorMut,
    mutateItem,
  } = useCategoryMutation();

  // campi
  const [title, setTitle] = useState<string>("");
  const [order, setOrder] = useState<number>(0);
  const [type, setType] = useState<string[]>([]);
  const [faq, setTypeFaq] = useState<boolean>(false);
  const [download, setTypeDownload] = useState<boolean>(false);
  const [translations, setTranslations] = useState<Category["translation"]>({});

  const [showNotificationSuccess, setShowNotificationSuccess] =
    useState<boolean>(false);

  const handleChangeString =
    (setter: Dispatch<SetStateAction<string>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
  const handleChangeNumber =
    (setter: Dispatch<SetStateAction<number>>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      setter(parseInt(event.target.value));
    };
  const handleChangeField =
    (field: CountryKey) => (event: ChangeEvent<HTMLInputElement>) => {
      setTranslations({
        ...translations,
        [field]: event.target.value,
      });
    };

  useEffect(() => {
    setTitle(categorie?.title || "");
    setOrder(categorie?.order || 0);
    setTypeFaq(categorie?.type.some((el) => el === "faq") || false);
    setTypeDownload(categorie?.type.some((el) => el === "download") || false);
    setType(categorie?.type || []);
    setTranslations(categorie?.translation || {});
  }, [categorie]);

  const handleSave = () => {
    if (faq) {
      if (!type.some((el) => el === "faq")) {
        type.push("faq");
      }
    } else {
      if (type.some((el) => el === "faq")) {
        const index = type.indexOf("faq");
        type.splice(index, 1);
      }
    }
    if (download) {
      if (!type.some((el) => el === "download")) {
        type.push("download");
      }
    } else {
      if (type.some((el) => el === "download")) {
        const index = type.indexOf("download");
        type.splice(index, 1);
      }
    }
    const oldCategorie = categorie || {};
    mutateItem({
      ...oldCategorie,
      title,
      order,
      type,
      translation: translations,
    } as Category).then((res) => {
      setShowNotificationSuccess(true);
      setTimeout(() => {
        setShowNotificationSuccess(false);
        if (isModify) {
          refetch();
        } else {
          navigate(`/categories/${res.uid}`);
        }
      }, 2000);
    });
  };

  if (!categorie && !isStale && isLoading === false && isModify) {
    navigate("../");
  }
  const isFormValid =
    (faq === false && download === false) || title === "" || order === 0;
  return (
    <div className="container mx-auto">
      {isLoading && (
        <div className="page-loading">
          <CircularProgress />
        </div>
      )}
      {(!isLoading || !isModify) && (
        <>
          <Link to=".." relative="path">
            <Button
              className="buttons-style"
              variant="contained"
              onClick={() => {
                navigate("../");
              }}
            >
              <ArrowCircleLeftIcon className="icon-button" />{" "}
              <span className="w-full">Go back</span>
            </Button>
          </Link>
          <div className="my-4">
            <hr></hr>
          </div>
          <div className="flex justify-between">
            <h1 className="text-3xl">
              {isModify ? `Edit Category: ${categorie?.title}` : "New Category"}
            </h1>
            <Button
              variant="contained"
              onClick={handleSave}
              className="buttons-style"
              disabled={isFormValid}
            >
              <SaveIcon className="icon-button" />
              <span className="w-full">Save</span>
            </Button>
          </div>
          <div className="grid grid-rows-1 grid-cols-4 gap-4">
            <div className="flex flex-col col-span-1">
              <div className="">
                <p className="font-semibold">Title</p>
                <div className="mt-2">
                  <TextField
                    error={title === ""}
                    fullWidth
                    label="Title"
                    variant="outlined"
                    size="small"
                    type="text"
                    name="title"
                    value={title}
                    onChange={handleChangeString(setTitle)}
                  />
                </div>
                {/* </div> */}
                <div>
                  <p className="font-semibold">Order</p>
                  <div className="mt-2">
                    <TextField
                      error={order === 0}
                      fullWidth
                      label="Order"
                      variant="outlined"
                      size="small"
                      type="number"
                      name="order"
                      value={order}
                      onChange={handleChangeNumber(setOrder)}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="flex items-center">
                    <p className="font-semibold">Faq</p>
                    <Checkbox
                      sx={{
                        color:
                          faq === false && download === false
                            ? "rgb(211, 47, 47)"
                            : "",
                      }}
                      checked={faq}
                      onChange={(event) => setTypeFaq(event.target.checked)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                  <div className="flex items-center">
                    <span className="font-semibold">Download</span>
                    <Checkbox
                      sx={{
                        color:
                          faq === false && download === false
                            ? "rgb(211, 47, 47)"
                            : "",
                      }}
                      checked={download}
                      onChange={(event) =>
                        setTypeDownload(event.target.checked)
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3 flex flex-col">
              <p className="font-semibold">Title</p>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="IT"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="it"
                  value={translations.it || ""}
                  onChange={handleChangeField("it")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="FR"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="fr"
                  value={translations.fr || ""}
                  onChange={handleChangeField("fr")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="ES"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="es"
                  value={translations.es || ""}
                  onChange={handleChangeField("es")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="PT"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="pt"
                  value={translations.pt || ""}
                  onChange={handleChangeField("pt")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="DE"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="de"
                  value={translations.de || ""}
                  onChange={handleChangeField("de")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="PL"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="pl"
                  value={translations.pl || ""}
                  onChange={handleChangeField("pl")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="BE"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="be"
                  value={translations.be || ""}
                  onChange={handleChangeField("be")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="NL"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="nl"
                  value={translations.nl || ""}
                  onChange={handleChangeField("nl")}
                />
              </div>
              <div className="mt-2">
                <TextField
                  fullWidth
                  label="TR"
                  variant="outlined"
                  size="small"
                  type="text"
                  name="tr"
                  value={translations.tr || ""}
                  onChange={handleChangeField("tr")}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {showNotificationSuccess && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          message="Operation carried out successfully"
        >
          <Alert
            sx={{ color: "white", backgroundColor: "#1D4EBB" }}
            severity="info"
          >
            Operation carried out successfully
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
